import clsx from "clsx";
import React from "react";

interface Item{
  logo: string;
  theme: string;  
  title: string;
  content: string;
  color: string;
}

export const PrimaryCard = (  obj: Item, key: number) => {
  return (
    <div
      className={`bg-[#01203AE5] w-[80%] md:w-[40%] flex flex-col items-center shadow-lg text-center pb-20`}
    >
      <img
        alt="#"
        className={clsx("object-cover h-[150px]")}
        src={process.env.PUBLIC_URL + obj.logo}
      />
      <div className="flex flex-1 h-full  flex-col">
        <div className="h-[40%] flex-1 text-2xl md:text-2xl font-bold text-[#4BD1A0]">
          {obj.title}
        </div>
        <div className="h-[60%]  text-md  mx-auto text-white flex items-start">
          {obj.content}
        </div>
      </div>
    </div>
  );
};

interface SecondaryCardItem{
  logo: string;
  theme: string; 
  color: string; 
  title: string;
}

interface SecondayCardItemProps extends SecondaryCardItem {
  index: number;
}

export const SecondaryCard = (obj: SecondayCardItemProps) => {
  const titleParts = obj.title.split('\\n');
  return (
    <React.Fragment>
      <div
        className={`w-[80%] md:w-1/4 text-black flex flex-col items-center rounded-3xl px-2 md:px-10 py-2 md:py-10 gap-8`}
      >
        <img
          className="h-[120px] w-[120px]"
          alt="#"
          src={process.env.PUBLIC_URL + obj.logo}
        />
        <div className="h-[50%] flex-1 text-2xl md:text-2xl font-bold text-[#01203AE5] whitespace-nowrap">
        {titleParts.map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index < titleParts.length - 1 && <br />}  {/* Only add <br/> if it's not the last part */}
            </React.Fragment>
          ))}
        </div>
      </div>
      <img
        src={process.env.PUBLIC_URL + "assets/how-it-works/5.png"}
        className={clsx(
          obj.index === 3 && "hidden",
          "rotate-0 md:rotate-[270deg] h-[60px]"
        )}
        alt=""
      />
    </React.Fragment>
  );
};
