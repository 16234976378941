import React from "react";
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import { AI, RightArrow } from "../components/icons";
import AppWrapper from "../components/ui/AppWrapper";
import UserProfileMenu from "../components/UserProfile/menu"
import { useState } from "react";
interface NewTemplatePageProps { }

const scriptTemplates = [
  {
    title: "लीफ फोल्डर सलाह एवं कीटनाशक",
    script: "नमस्ते {name}, आपने देहात के कस्टमर केयर में लीफ फोल्डर के बारे में जानकारी प्राप्त करने के लिए क्वेरी डाली थी। लीफ फोल्डर धान की फसल का एक प्रमुख कीट है जो पत्तियों को मोड़कर अंदर से क्लोरोफिल खा जाता है। इसे इसके भूरे पंखों और हल्के पीले अंडों से पहचानें ...",
  },
  {
    title: "Khatabook business loan personalised",
    script: "Hi {Name}, Need quick funds for your dreams? Our personal loan service offers fast approval, flexible repayment options, and competitive interest rates for you business { business name }..Whether it's for a vacation, education, or ...",
  }
]

const FullScreenTemplateLoader: React.FC = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the loader is on top of other content
    }}
  >
    <div className="text-black font-semibold text-lg">
      Turning Your Script into a personalised template... Just a Moment! &nbsp; &nbsp;&nbsp;&nbsp;
    </div>
    <CircularProgress color="inherit" />
  </Box>
);


const NewTemplatePage: React.FC<NewTemplatePageProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default navigation
    const url = event.currentTarget.href;
    setIsLoading(true);
    // Set a delay before navigating
    setTimeout(() => {
      window.location.href = url;
      // setIsLoading(false);
    }, 10000); // 2000ms = 2 seconds delay
  };

  return (
    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Create Template</h1>
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col items-center w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col w-[880px] gap-y-12 mt-12">
            <div className="flex flex-col gap-y-8">
              <h2 className="text-2xl font-medium text-[#19213D]">Write your own script</h2>
              <div className="w-[786px] p-6 rounded-xl bg-white border border-[#F0F2F5] shadow-2xl">
                <textarea className="w-full h-28 border focus:outline-none border-[#F0F2F5] rounded-lg p-3 resize-none" placeholder="Insert your script..." />
                <div className="flex items-center border border-[#F0F2F5] rounded-lg justify-end gap-6 mt-4 p-3">
                  <input className="w-full py-3 resize-none focus:outline-none" placeholder="Instruct to edit your script" />
                  <button className="flex items-center gap-3 p-3 rounded-lg flex-shrink-0 bg-black border border-[#292D32]">
                    <AI className="w-4 h-4 fill-current text-white" />
                    <span className="text-white text-sm font-medium">Edit</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-[#F0F2F5] h-[1px] w-full" />
            <div className="flex flex-col gap-y-6">
              <h2 className="text-2xl font-medium text-[#19213D]">Choose a Script Template</h2>
              <div className="flex items-center gap-2">
                <button className="text-xs py-1 px-2 rounded-full text-[#4C5EFF] border border-[#4C5EFF] bg-[#E3EBFF] font-medium">Marketing</button>
                <button className="text-xs py-1 px-2 rounded-full text-[#1191EE] border border-[#E9F3FF] bg-[#E9F3FF] font-medium">Product Recommendations</button>
                <button className="text-xs py-1 px-2 rounded-full text-[#1191EE] border border-[#E9F3FF] bg-[#E9F3FF] font-medium">Product Advisory</button>
              </div>
              <div className="grid grid-cols-4 gap-4">
                {scriptTemplates.map(item => (
                  <div className="flex flex-col bg-white rounded-lg hover:border-[#2388FF] border shadow-sm py-6 px-4 gap-3">
                    <h3 className="text-[#19213D] font-medium text-sm">{item.title}</h3>
                    <p className="text-[#666F8D] text-sm">{item.script}</p>
                    <button className="flex items-center gap-x-2 text-[#2388FF]">
                      <span className="text-sm font-medium">View Template</span>
                      <RightArrow className="w-4 h-4 fill-current" />
                    </button>
                  </div>
                ))}

              </div>
            </div>
            <div className="flex items-center justify-center mb-8">
              <a href="/video/new/personalise" onClick={handleClick} className="flex items-center gap-3 p-3 rounded-lg flex-shrink-0 bg-black border border-[#292D32]">
                <AI className="w-4 h-4 fill-current text-white" />
                <span className="text-white text-sm font-medium">Generate Video</span>
                {isLoading && <FullScreenTemplateLoader />} {/* Show full-screen loader */}
              </a>
            </div>
          </div>
        </article>
      </section>
    </AppWrapper>
  );
};

export default NewTemplatePage;
