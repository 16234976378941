import React from "react";
import { SecondaryCard } from "../Cards";

export const HowItWorks = () => {
  const CardData = [
    {
      logo: "assets/how-it-works/create_template_video.png",
      theme: "bg-[#000000]",
      color: "white",
      title: "Create\\nTemplate Video",
    },
    {
      logo: "assets/how-it-works/approve_the_video.png",
      theme: "bg-[#035DA9]",
      color: "white",
      title: "Approve\\nThe Video",
    },
    {
      logo: "assets/how-it-works/variable_in_sheet.png",
      theme: "bg-[#EDEDED]",
      color: "black",
      title: "Provide Variable\\nValues In A Sheet",
    },
    {
      logo: "assets/how-it-works/personalize_and_distribute.png",
      theme: "bg-[#4BD1A0]",
      color: "black",
      title: "Personalize\\n& Distribute",
    },
  ];
  return (
    <div id="how-it-works" className="bg-white w-full">
      <div className="w-[80%] mx-auto flex flex-wrap justify-center px-10 md:px-0 items-center">
        <div className="flex flex-col gap-4 items-center justify-center w-full py-5 px-10 md:px-0">
          <div className="whitespace-nowrap rounded-2xl py-4 px-10 text-2xl md:text-4xl font-bold">
            HOW IT WORKS
          </div>
        </div>
        <div className="flex flex-col  items-center justify-center w-full md:flex-row">
          {CardData.map((item, index) => {
            return <SecondaryCard {...item} index={index} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};
