import React from "react";
import { Link } from "react-router-dom";
import { AI, RightArrow } from "../components/icons";
import { SavedTemplateCard } from "../components/ui";
import AppWrapper from "../components/ui/AppWrapper";
import UserProfileMenu from "../components/UserProfile/menu"
interface SavedTemplatesPageProps { }

const templateData = [
  {
    id: "photos",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/template2.jpg",
    header: "लीफ फोल्डर सलाह एवं कीटनाशक",
    date: "23 August 2024",
    variables: "Agriculture",
    prompt: "नमस्ते रामू जी आपने देहात के कस्टमर केयर में लीफ फोल्डर के बारे में जानकारी प्राप्त करने के लिए क्वेरी डाली थी। लीफ फोल्डर धान की फसल का एक प्रमुख कीट है जो पत्तियों को मोड़कर अंदर ..."
  },
  {
    id: "photos",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/template1.jpg",
    header: "Leaf Folder advisory & insecticides",
    date: "23 July 2024",
    variables: "Agriculture",
    prompt: "Hello Ramu Ji, You had inquired about the leaf folder in paddy crops through DeHaat's customer care. The leaf folder is a significant ..."
  },
  {
    id: "photos",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/template3.jpg",
    header: "Khatabook Business loan Personalised",
    date: "25 July 2024",
    variables: "Finance",
    prompt: "Hi Amit,Welcome to Khatabook. We are here to help you with personalised credit services. Khatabook provides loans for your business. "
  }
];

const SavedTemplatesPage: React.FC<SavedTemplatesPageProps> = () => {
  return (
    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Saved Templates</h1>
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col flex-1 items-center mt-16">
            <div className="flex flex-col items-center gap-4">
              <div className="flex items-end justify-between w-full">
                <h2 className="text-lg font-semibold text-[#1F2633]">Choose from Saved Templates</h2>
                <button className="flex items-center gap-x-3">
                  <span className="text-[#2388FF] text-xs font-medium">Browse All</span>
                  <RightArrow className="w-4 h-4 fill-current text-[#2388FF]" />
                </button>
              </div>
              {templateData.map(item => (
                <SavedTemplateCard
                  src={item.src}
                  variable={item.variables}
                  date={item.date}
                  prompt={item.prompt}
                  header={item.header}
                />
              ))}
            </div>
          </div>
          <div className="flex mb-8 items-center justify-center mt-8">
            <Link to="/template/saved/run" className="flex items-center gap-3 p-3 rounded-lg bg-black border border-[#292D32]">
              <AI className="w-4 h-4 fill-current text-white" />
              <span className="text-white text-sm font-medium">Start Campaign</span>
            </Link>
          </div>
        </article>
      </section>
    </AppWrapper>
  );
};

export default SavedTemplatesPage;
