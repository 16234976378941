import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path fill="current" d="M10.472 4.471a.667.667 0 0 0-.943-.942l-4 4a.667.667 0 0 0 0 .942l4 4a.667.667 0 1 0 .943-.942L6.942 8l3.53-3.529Z" />
  </svg>
);

export default SvgComponent;
