import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeft, Check, CheckCircle, FilledPlay, Mic, Times, UserCircle, VoiceMessage } from "../components/icons";
import { Select } from "../components/ui";
import AppWrapper from "../components/ui/AppWrapper";
import UserProfileMenu from "../components/UserProfile/menu"

interface EditVideoPageProps { }

const FullScreenLoader: React.FC = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the loader is on top of other content
    }}
  >
    <div className="text-black font-semibold text-lg">
      Applying Changes... &nbsp; &nbsp;&nbsp;&nbsp;
    </div>
    <CircularProgress color="inherit" />
  </Box>
);

const avatars = [
  { id: "1", value: "https://s3-alpha-sig.figma.com/img/3e2c/4379/f1550bab18119b852743880ccfe6bab6?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=CGsN7P7wRifDGiUSIbtN9fclWHRUh-uxTE-HgLGjTayE4zWejaEhW17ofSbBWI4EZXajIV8Xwnm~U836ZZlH5lCP~B0mBgntAXQuejBZlvUPyqinDDEqcLn1vHtaQyazXj1Etz8-aaZ9D2uDHWAZa95NEeaNcgzzfJ6xxbe1usGgfgt4~o8izvynnknM8WXgQllzrqeuUWSzF-2wjv3ZHOksQyAjzLM1bzd5XmbaBZanqraQyAoEiPaFvUuh8Wr72b~1KmKxnHk5XoeF9czIbZDtHL-4VLjHZSy9uCrygMhAIrfOYY7OaySIk2-osTbp3gu4POgTnddwBlzKR2NbIA__" },
  { id: "2", value: "https://d3o847c916r6no.cloudfront.net/ws_assets/salman.png" },
  { id: "3", value: "https://s3-alpha-sig.figma.com/img/d966/47e7/abe23ae80e15689642597b8773b2ff42?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DJJ0RKcKR4LWF~RT7BkMKuWBtgThEBY5XyCzRdfI8OWs41DMAeERx-~O48jt9PdkRVVWYi1AnYQSHoAarD2eS5jZO1Dq8wZ~Fv739P6xBvU2ZuI9d8~bt8xY0gs-GHJKFfPgJbL5QxvG9DJP0rz35571H2YHeSLd1YV42izI-NbybpqqKCjjS1RF59iO9nxP1Vk0WyXP4kU4EFWjCW1S3AwVg4Ok~pVhK4KTZ4MdByr~ukQqIn0CpTZXkY6Vpvkc-Y37ihcDyQFCXSCIKZRCnGfPvRudb2u38FONbvaljamQmKxNVPVFTiOQF9GMJJJ9iLn5uVYMAfIF6ntwgk7JOQ__" },
  { id: "4", value: "https://s3-alpha-sig.figma.com/img/9a6b/162f/033702412b3caf7d4d4d0994cc5483f8?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pKrKELWC305XYZBZyaMi9sjvWzl7llbOy0NiJcC1PnpO-E2V3SS7pWkmnAdWB8v3oREhaHnGj69dfnqhavdK8umtJI26jtCKDk4F2R6jxjr2~aKnSTafKH5zVngS9t0FXMvtQlAG4Nb7Efia-K89UY-djUe0Lrpw9QMAdKmcRkEcyi2lwlKEQLVbDPDQhrvE-9SsEkESTvzXmk03cwEq9RZbI3gYQxW1mWpBRd03vTsDBH7thxuAxAKB1pztXI9uPG2xqacRrBxx-sEwxy7thjONfqeE77DqrkXGwaipe50d65RA3oQs2nShePRFj6-Qv271nfooyGDE50-BsUQIdA__" },
  { id: "5", value: "https://s3-alpha-sig.figma.com/img/ecf6/f612/178992c5339d3400af3be2ce8e198dde?Expires=1725840000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=NuvYpmgx4EkVbCkJ7tb-tUJNHOWi8SOzwLe~7Oz2o5UPBKhRiW1s8s7LC6qyzyUDYoPhq-qUTu9mAfvxcVtaUCYAhw2z7bRocESSjZiK5CsZ4VSiJa9OoHw-T2EPAjPaJgYflQrd7DBk4MHvhv-LO2bDwfJ0fpIBOhijFmo5J95~kNjTPZMZowHCRKMSwAoQEZk29jJ4UOFSPthuudhVig4mnkfwu3vOSGH7AEgVOPuQr2CmsppRxTBffQfLa0kAwSKJZEMw2x1BOOaVDAEb1kbJ0OAt9NmrCupcyd0S-3atcJ7HPu53ogCzcwU5b2pUNxYfnjKpfCaZ8ZRlxPAUUw__" },
];

const gender = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
  {
    value: "other",
    label: "Other",
  },
];

const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "es",
    label: "Spanish",
  },
  {
    value: "fr",
    label: "French",
  },
];

const voices = [
  {
    value: "voice1",
    label: "Voice 1",
  },
  {
    value: "voice2",
    label: "Voice 2",
  },
  {
    value: "voice3",
    label: "Voice 3",
  },
];

const EditVideoPage: React.FC<EditVideoPageProps> = () => {
  const [activeAvatar, setActiveAvatar] = useState<string>(avatars[2].id);
  const [activeGender, setActiveGender] = useState<string>("");
  const [activeVoice, setActiveVoice] = useState<string>("");
  const [activeLanguage, setActiveLanguage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default navigation
    const url = event.currentTarget.href;
    setIsLoading(true);
    // Set a delay before navigating
    setTimeout(() => {
      window.location.href = url;
      // setIsLoading(false);
    }, 10000); // 2000ms = 2 seconds delay
  };
  const videoSource = useMemo(() => {
    let videoSource: string = "";
    console.log(activeAvatar)
    if (activeAvatar === "3") {
      videoSource = "https://d3o847c916r6no.cloudfront.net/ws_assets/video_output_hi.mp4";
    } else if (activeAvatar === "2") {
      videoSource = "https://d3o847c916r6no.cloudfront.net/ws_assets/personalisation_male_hi.mp4";
    } else {
      videoSource = "https://d3o847c916r6no.cloudfront.net/ws_assets/video_output_hi.mp4";
    }
    console.log(videoSource)
    return videoSource;
  }, [activeAvatar]);
  return (

    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Edit Video</h1>
          {/* <User /> */}
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col flex-1 items-center relative my-12">
            <div className="flex flex-col items-center gap-x-4 w-[820px] p-6 rounded-xl bg-white border border-[#F0F2F5] shadow-2xl">
              <div className="flex items-center self-start gap-4">
                <Link to="/video/new/preview">
                  <ArrowLeft className="w-4 h-4 text-[#666F8D]" />
                </Link>
                <div className="flex items-center divide-x divide-[#D6D6D6] border border-[#D6D6D6] bg-white rounded-lg shadow-sm">
                  <button className="py-3 px-4 flex items-center justify-center gap-x-2">
                    <UserCircle className="w-5 h-5 fill-current text-[#19213D]" />
                    <span className="text-[#19213D] text-xs font-medium">Avatar</span>
                  </button>
                  <button className="py-3 px-4 flex items-center justify-center gap-x-2">
                    <Mic className="w-5 h-5 fill-current text-[#C7CED9]" />
                    <span className="text-[#C7CED9] text-xs font-medium">Voice</span>
                  </button>
                </div>
              </div>
              <div className="grid grid-cols-3 gap-6 w-full mt-6">
                <div className="col-span-2 flex flex-col border border-[#E3E6EA] rounded-lg p-6">
                  <video className="w-full aspect-video rounded-xl" controls>
                    <source src={videoSource} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                  <div className="flex gap-x-6 border border-[#F0F2F5] shadow-sm rounded-lg flex-nowrap mt-4 p-4 overflow-x-auto">
                    {avatars.map((avatar) => (
                      <button key={avatar.id} className="w-16 h-16 relative flex-shrink-0 rounded-full border-2 border-[#F0F2F5]" onClick={setActiveAvatar.bind(null, avatar.id)}>
                        <img src={avatar.value} alt="Avatar" className="w-full h-full object-cover rounded-full overflow-hidden" />
                        {activeAvatar === avatar.id && (
                          <div className="absolute -top-1 -right-1">
                            <CheckCircle className="w-6 h-6 text-black fill-current" />
                          </div>
                        )}
                      </button>
                    ))}
                  </div>
                  <div className="flex items-center justify-center gap-6 mt-6 py-2">
                    <span className="text-sm font-medium text-[#101828]">Select Gender</span>
                    <div className="w-48">
                      <Select options={gender} placeholder="Select" value={activeGender} onChange={setActiveGender} />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col border border-[#E3E6EA] rounded-lg p-6">
                  <div className="flex flex-col gap-y-2">
                    <span className="text-sm font-medium text-[#101828]">Template Content</span>
                    <textarea className="w-full h-28 border focus:outline-none text-sm border-[#F0F2F5] rounded-lg p-3 resize-none shadow-sm" placeholder="Enter script to test Voice" />
                  </div>
                  <div className="flex flex-col gap-y-2 my-6">
                    <span className="text-sm font-medium text-[#101828]">Select Language</span>
                    <Select options={languages} placeholder="Select" value={activeLanguage} onChange={setActiveLanguage} />
                  </div>
                  <div className="flex flex-col gap-y-2">
                    <span className="text-sm font-medium text-[#101828]">Select Voice</span>
                    <Select options={voices} placeholder="Select" value={activeVoice} onChange={setActiveVoice} />
                  </div>
                  <div className="flex items-center justify-between mt-6 gap-x-1 shadow-sm rounded-lg px-2 py-1 border border-[#F0F2F5]">
                    <FilledPlay className="w-3 flex-shrink-0 h-3 text-[#19213D] fill-current" />
                    <VoiceMessage className="flex-1 h-6 text-[#19213D] fill-current" />
                    <span className="text-[8px] font-medium text-[#666F8D]">02:23</span>
                  </div>
                  <button className="flex items-center justify-center gap-3 p-3 mt-6 rounded-lg flex-shrink-0 bg-black border border-[#292D32]">
                    <span className="text-white text-sm">Preview</span>
                  </button>
                </div>
              </div>
              <div className="flex items-center justify-center mt-6 gap-x-4">
                <Link to="/video/new/preview" className="flex items-center gap-3 p-3 rounded-lg flex-shrink-0 bg-[#F7F8FA] border border-[#F0F2F5]">
                  <Times className="w-4 h-4 fill-current text-[#19213D]" />
                  <span className="text-[#19213D] text-sm">Discard Changes</span>
                </Link>
                {!isLoading && (
                  <a href={`/video/new/preview?avatar_id=${activeAvatar}`} onClick={handleClick} className="flex items-center gap-3 p-3 rounded-lg flex-shrink-0 bg-black border border-[#292D32]">
                    <Check className="w-4 h-4 fill-current text-white" />
                    <span className="text-white text-sm">Apply Changes</span>
                  </a>
                )};
                {isLoading && <FullScreenLoader />} {/* Show full-screen loader */}
              </div>
            </div>
          </div>
        </article>
      </section>
    </AppWrapper>
  );
};

export default EditVideoPage;
