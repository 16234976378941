import React from "react";
import { File, Gallery, People } from "../icons";
import NavMenuBlock from "./NavMenuBlock";

interface NavigationProps {
  isHorizontallyCollapsed?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ isHorizontallyCollapsed }) => {
  return (
    <nav className="w-full flex-1 flex flex-col px-6 pt-10 gap-4">
      <NavMenuBlock
        title="Video Generation"
        isHorizontallyCollapsed={isHorizontallyCollapsed}
        icon={(props: any) => <Gallery {...props} />}
        links={[
          { title: "New Video", to: "/video/new" },
          { title: "Video History", to: "/video/history" },
        ]}
      />
      <NavMenuBlock
        title="Personalised Video"
        isHorizontallyCollapsed={isHorizontallyCollapsed}
        icon={(props: any) => <People {...props} />}
        links={[
          { title: "New Template", to: "/template/new" },
          { title: "Saved Template", to: "/template/saved" },
          // { title: "Report to Video", to: "/template/report" },
        ]}
      />
      <NavMenuBlock
        title="Assets"
        isHorizontallyCollapsed={isHorizontallyCollapsed}
        icon={(props: any) => <File {...props} />}
        links={[
          { title: "Media Library", to: "/media-library" },
          { title: "Knowledge Base", to: "/knowledge-base" },
        ]}
      />
    </nav>
  );
};

export default Navigation;
