import React from "react";

export const Footer = () => {
  const divStyle = {
    width: '50%',
  };
  return (
    <div className="flex flex-col gap-7 bg-white p-10">
      <div className="w-[90%] md:w-[70%] h-[1px] mx-auto bg-black"></div>
      <div className="w-full md:w-[80%] mx-auto flex flex-wrap gap-4 md:gap-10 justify-between">
        <div className="flex flex-col gap-4 w-auto" style={divStyle}>
          <div className="flex gap-5">
            <svg
              width="19"
              height="21"
              viewBox="0 0 19 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6664 5.79545C12.9095 5.79545 13.8663 6.84042 13.8663 9.29034H15.9995C15.9995 5.53334 14.1062 3.46553 10.6664 3.46553V5.79545ZM14.3164 12.1363C14.1114 11.9329 13.8421 11.8244 13.5653 11.8337C13.2884 11.8431 13.0258 11.9695 12.8327 12.1864L10.2803 15.0534C9.66594 14.9253 8.4308 14.5047 7.1594 13.1196C5.888 11.7298 5.50295 10.3772 5.38883 9.71089L8.01162 6.92197C8.21045 6.71126 8.32642 6.42432 8.33498 6.12188C8.34354 5.81944 8.24402 5.52523 8.05749 5.30151L4.11636 0.568272C3.92975 0.34385 3.67038 0.207721 3.39336 0.188797C3.11633 0.169873 2.84346 0.269644 2.6327 0.466921L0.318151 2.63491C0.133746 2.83705 0.0236804 3.10608 0.00883364 3.39097C-0.00716554 3.68221 -0.312216 10.5811 4.58567 15.9329C8.85851 20.5986 14.2108 20.9399 15.6848 20.9399C15.9003 20.9399 16.0325 20.933 16.0677 20.9306C16.3285 20.9147 16.5747 20.7939 16.7589 20.5916L18.7428 18.0625C18.9236 17.8324 19.0151 17.5345 18.998 17.2319C18.9808 16.9294 18.8564 16.646 18.6511 16.442L14.3164 12.1363Z"
                fill="black"
                fillOpacity="0.83"
              />
            </svg>
            <div>+91-95822 06907</div>
          </div>
          <div className="flex gap-5">
            <svg
              width="25"
              height="25"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.4538 0.510742H0.773438V19.3159H21.4538V0.510742ZM19.3857 5.21203L11.1136 11.0886L2.84147 5.21203V2.86139L11.1136 8.738L19.3857 2.86139V5.21203Z"
                fill="black"
                fillOpacity="0.83"
              />
            </svg>
            <div>support@bingeclip.ai</div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex gap-5">
            <svg
              width="17"
              height="25"
              viewBox="0 0 17 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.72443 0.295898C6.55018 0.298931 4.46572 1.32149 2.92829 3.13926C1.39086 4.95703 0.526011 7.42159 0.523446 9.9923C0.520842 12.0931 1.10123 14.1369 2.17557 15.8101C2.17557 15.8101 2.39924 16.1583 2.43577 16.2086L8.72443 24.9777L15.0161 16.2042C15.0489 16.1575 15.2733 15.8101 15.2733 15.8101L15.274 15.8075C16.3479 14.135 16.928 12.0921 16.9254 9.9923C16.9229 7.42159 16.058 4.95703 14.5206 3.13926C12.9831 1.32149 10.8987 0.298931 8.72443 0.295898ZM8.72443 13.5183C8.13462 13.5183 7.55804 13.3115 7.06763 12.924C6.57721 12.5366 6.19498 11.9859 5.96926 11.3416C5.74355 10.6973 5.68449 9.98839 5.79956 9.30442C5.91463 8.62045 6.19865 7.99218 6.61572 7.49907C7.03278 7.00595 7.56416 6.67014 8.14264 6.53409C8.72113 6.39804 9.32074 6.46786 9.86566 6.73474C10.4106 7.00161 10.8763 7.45354 11.204 8.03338C11.5317 8.61322 11.7066 9.29493 11.7066 9.9923C11.7056 10.9271 11.3911 11.8233 10.8321 12.4842C10.273 13.1452 9.51505 13.5171 8.72443 13.5183Z"
                fill="black"
                fillOpacity="0.83"
              />
            </svg>
            <div>
              Genhex AI Technologies Private Limited<br />
                Desk 54, Kokarya, Business Synergy Center<br />
                Nagananda Commercial Complex, 185/A, Second floor<br />
                18th Main Road Jayanagar 9th Block, <br />
                Bengaluru - 560041
            </div>
          </div>
        </div>
        <div>
          {/*           <div className="flex"> */}
          {/*             <input */}
          {/*               placeholder="Your email address" */}
          {/*               className="border border-black px-4" */}
          {/*             /> */}
          {/*             <button className="bg-[#000000C9] p-2 text-white">Subscribe</button> */}
          {/*           </div> */}
        </div>
      </div>
    </div>
  );
};
