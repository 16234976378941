import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375M20.625 19.5c.621 0 1.125-.504 1.125-1.125M20.625 19.5h-1.5A1.125 1.125 0 0 1 18 18.375m-15.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125M6 18.375v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M2.25 5.625c0-.621.504-1.125 1.125-1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 7.5a1.125 1.125 0 0 1-1.125-1.125v-1.5c0-.621.504-1.125 1.125-1.125m0 3.75h1.5m16.875 2.625V5.625m0 12.75v-1.5c0-.621-.504-1.125-1.125-1.125M21.75 5.625c0-.621-.504-1.125-1.125-1.125m1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m0 7.5c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125m1.5-11.25H3.375m17.25 0h-1.5C18.504 4.5 18 5.004 18 5.625M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m12 0c0-.621-.504-1.125-1.125-1.125h-9.75C6.504 4.5 6 5.004 6 5.625m12 0v1.5a1.125 1.125 0 0 0 1.125 1.125M18 5.625v5.25m2.625-2.625h-1.5m1.5 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M6 5.625v1.5c0 .621-.504 1.125-1.125 1.125M6 5.625v5.25M3.375 8.25h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 8.754 6 9.375v1.5M3.375 12h1.5M6 10.875A1.125 1.125 0 0 0 7.125 12M6 10.875C6 11.496 5.496 12 4.875 12m14.25-3.75c-.621 0-1.125.504-1.125 1.125v1.5m0 0c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m1.5 0h-1.5m-12 0h9.75m-9.75 0C6.504 12 6 12.504 6 13.125M16.875 12c.621 0 1.125.504 1.125 1.125m-12 0v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m14.25 0c-.621 0-1.125.504-1.125 1.125m0 0v1.5c0 .621.504 1.125 1.125 1.125"
    />
  </svg>
);

export default SvgComponent;
