import React, { useState } from "react";
import { Photo, Play } from "../components/icons";
import { Media, SearchInput, Tabs } from "../components/ui";
import AppWrapper from "../components/ui/AppWrapper";
import UserProfileMenu from "../components/UserProfile/menu"

const allMedia = [
  {
    id: "1",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/asset1.jpeg"
  },
  {
    id: "2",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/asset2.jpeg"
  },
  {
    id: "3",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/asset3.jpeg"
  },
  {
    id: "4",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/asset4.jpeg"
  },
  {
    id: "5",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/asset5.jpeg"
  }
]

const tabData = [
  {
    id: "photos",
    label: "Photos",
    icon: (props: any) => <Photo {...props} />,
    data: [""],
  },
  {
    id: "videos",
    label: "Videos",
    icon: (props: any) => <Play {...props} />,
    data: [""],
  },
];
interface MediaLibraryPageProps { }


const MediaLibraryPage: React.FC<MediaLibraryPageProps> = () => {
  const [activeTab, setActiveTab] = useState<string>("photos");

  return (
    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Media Library</h1>
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col items-center w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col w-[880px] gap-y-12 mt-12">
            <div className="flex flex-col gap-y-6 items-start">
              <div className="flex flex-col">
                <h2 className="text-2xl font-medium text-[#19213D]">Explore All Assets</h2>
                <span className="text-sm text-[#666F8D] mt-1">Easily access, organize, and track your media content with our library.</span>
              </div>
              <div className="flex items-center justify-between w-full">
                <Tabs
                  value={activeTab}
                  options={tabData.map((tab) => ({
                    id: tab.id,
                    label: tab.label,
                    icon: tab.icon,
                  }))}
                  onChange={setActiveTab}
                />
                <div className="w-80">
                  <SearchInput placeholder="Search..." />
                </div>
              </div>
              <div className="grid grid-cols-4 gap-6">
                {allMedia.map(item => (
                  <Media
                    id={item.id}
                    src={item.src}
                  />
                ))}
              </div>
            </div>
          </div>
        </article>
      </section>
    </AppWrapper>
  );
};

export default MediaLibraryPage;
