import clsx from "clsx";
import React, { useState } from "react";
import { VideoLayout } from "../VideoLayout";

export const CustomerStories = () => {
  const [id, setId] = useState(0);

  const customerStories = [
    {
      content:
        "Bid farewell to mundane reports! With BingeClip AI, transform traditional reports into vibrant and visually appealing videos. Communicate complex information effortlessly, ensuring that your reports not only convey accurate data but also captivate your audience.",
      title: "",
      video:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/reportovideo.mp4",
    },
    {
      content:
        "Keep your audience engaged with timely updates and reminders through personalized videos. Whether it's important announcements, promotions, or simple reminders, BingeClip AI ensures that your messages are delivered in a visually appealing and effective manner.",
      title: "",
      video:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/reminder.mp4",
    },

    {
      content:
        "In the competitive world of lead generation, stand out with videos & personalisation and acquire top of the funnel users, effectively reducing CAC.",
      title: "",
      video:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/lead_funnel.mp4",
    },
    {
      content:
        "Put your customer data to work, by using previous purchase behaviour to create unique upsell and cross sell opportunities. Craft personalized videos that speak directly to your audience's needs, increasing the likelihood of successful upsells and cross-sells.",
      title: "",
      video:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/upsell.mp4",
    },
  ];

  const handleId = (event: number) => {
    setId(event);
  };
  return (
    <div id="customer-stories">
      <div className="w-full">
        <div className="flex flex-col gap-4 items-center justify-center text-white w-full py-10 px-10 md:px-0">
          <div className="whitespace-nowrap rounded-2xl px-10 text-2xl md:text-4xl font-bold ">
            USE CASES
          </div>
        </div>
        <div className="w-[100%] md:w-[80%] flex justify-evenly text-white mx-auto text-center text-[8px] md:text-xl">
          <div
            onClick={() => {
              handleId(0);
            }}
            className="flex flex-col items-center gap-2 cursor-pointer"
          >
            <div>Report to Video</div>
            <div
              className={clsx(" w-[110%] h-1", id === 0 && "bg-[#4BD1A0]")}
            ></div>
          </div>
          <div
            onClick={() => {
              handleId(1);
            }}
            className="flex flex-col items-center gap-2 cursor-pointer"
          >
            <div>Updates and Reminders</div>
            <div
              className={clsx(" w-[110%] h-1", id === 1 && "bg-[#4BD1A0]")}
            ></div>
          </div>
          <div
            onClick={() => {
              handleId(2);
            }}
            className="flex flex-col items-center gap-2 cursor-pointer"
          >
            <div>Lead Funnel</div>
            <div
              className={clsx(" w-[110%] h-1", id === 2 && "bg-[#4BD1A0]")}
            ></div>
          </div>
          <div
            onClick={() => {
              handleId(3);
            }}
            className="flex flex-col items-center gap-2 cursor-pointer"
          >
            <div>Upsell & Cross-sell</div>
            <div
              className={clsx(" w-[110%] h-1", id === 3 && "bg-[#4BD1A0]")}
            ></div>
          </div>
        </div>
        <div className="w-[100%] md:w-[80%] mx-auto items-center md:items-start justify-center gap-10 p-10 h-full">
          <div className="flex flex-col md:flex-row gap-10">
            <VideoLayout
              className={"rounded-3xl w-full md:w-[55%]"}
              source={
                id === 0
                  ? customerStories[0].video
                  : id === 1
                    ? customerStories[1].video
                    : id === 2
                      ? customerStories[2].video
                      : id === 3 ? customerStories[3].video :
                        customerStories[0].video
              }
            />
            <div className="flex flex-col items-center w-full md:w-[45%] p-8 bg-white rounded-3xl">
              <div className="flex flex-col gap-4 text-[18px] h-full">
                <div>
                  {id === 0
                    ? customerStories[0].content
                    : id === 1
                      ? customerStories[1].content
                      : id === 2
                        ? customerStories[2].content
                        : id === 3 && customerStories[3].content}
                </div>
                <div className="flex-1 flex items-center font-bold">
                  <div className="">
                    {id === 0
                      ? customerStories[0].title
                      : id === 1
                        ? customerStories[1].title
                        : id === 2
                          ? customerStories[2].title
                          : id === 3 && customerStories[3].title}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
