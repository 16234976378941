import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15" fill="none" {...props}>
    <path fill="current" d="M14.192 8.313a.676.676 0 0 1-1.351 0v-6.08a.676.676 0 1 1 1.351 0v6.08Z" />
    <path
      fill="current"
      fillRule="evenodd"
      d="M14.7 8.31a1.182 1.182 0 0 1-2.365 0V2.23a1.182 1.182 0 0 1 2.364 0v6.08Zm-1.183.168a.169.169 0 0 0 .169-.169V2.23a.169.169 0 1 0-.338 0v6.08c0 .093.076.169.169.169ZM5.869 14.43c-.14.224-.392.467-.796.467-.508 0-.853-.351-1.026-.78-.17-.417-.217-.978-.154-1.65.052-.555.234-1.254.437-1.889.104-.327.217-.647.326-.935H2.033a.506.506 0 0 1-.056-.003c-.504-.056-.966-.185-1.298-.555-.317-.354-.435-.842-.5-1.39a.506.506 0 0 1-.004-.06V5.383c0-.834.274-2.026.67-3.005.2-.491.442-.96.72-1.317.257-.33.642-.688 1.143-.688h4.73V.88.373h.025a1.105 1.105 0 0 1 .17.018c.103.017.241.048.402.107.325.12.733.353 1.13.793.328.366.939.514 1.37.563a.902.902 0 0 1 .787.902v5.221c0 .47-.374.912-.897.888-.39-.017-.64.016-.809.088-.144.061-.264.165-.366.391a5.582 5.582 0 0 1-1.023 1.312c-.107.1-.214.195-.311.28l-.1.086c-.123.11-.233.212-.34.33a6.235 6.235 0 0 0-1.37 2.486c0 .005-.002.01-.003.014a2.39 2.39 0 0 1-.234.578Zm-.802-.666c.023-.063.044-.132.068-.213a7.246 7.246 0 0 1 1.588-2.877 5.13 5.13 0 0 1 .423-.411l.117-.103a9.15 9.15 0 0 0 .27-.241c.24-.226.5-.514.807-1.02.194-.416.484-.71.88-.878.332-.142.705-.18 1.088-.173V2.847c-.488-.067-1.343-.263-1.896-.878a1.859 1.859 0 0 0-.729-.52 1.115 1.115 0 0 0-.253-.062H2.71c-.006 0-.128.017-.345.297-.198.253-.399.628-.58 1.075-.363.897-.596 1.957-.596 2.624v2.221c.063.497.154.702.246.804.077.087.222.174.628.222H5.41a.507.507 0 0 1 .462.714c-.16.356-.384.932-.578 1.542-.196.617-.352 1.232-.393 1.676-.056.594.001.97.084 1.175a.57.57 0 0 0 .051.101c.01-.02.02-.045.03-.074Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SvgComponent;
