import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19} viewBox="0 0 20 19" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4.906 4.475a.89.89 0 0 1 1.32-.78l9.136 5.024a.89.89 0 0 1 0 1.562l-9.136 5.024a.89.89 0 0 1-1.32-.78V4.475Z" />
  </svg>
);

export default SvgComponent;
