import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} viewBox="0 0 20 21" fill="none" {...props}>
    <path
      stroke="currentColor"
      fill="none"
      strokeWidth={1.2}
      d="M18.379 10.776h-.489a7.87 7.87 0 0 1-1.456 4.007l.077.093h.001l1.867-4.1Zm0 0a8.356 8.356 0 0 1-1.623 4.393m1.623-4.393-1.623 4.393m0 0-.244-.292m.244.292-.244-.292m0 0Zm-14.402-4.1a7.87 7.87 0 0 0 1.456 4.006l-.077.093v.001l-.245.292a8.357 8.357 0 0 1-1.623-4.393h.489Zm0-1.2H1.62A8.401 8.401 0 0 1 9.4 1.796v.49a7.914 7.914 0 0 0-7.29 7.29Zm8.49-7.78a8.401 8.401 0 0 1 7.779 7.78h-.489a7.914 7.914 0 0 0-7.29-7.29v-.49ZM9.4 18.555a8.353 8.353 0 0 1-4.06-1.39l.314-.376A7.866 7.866 0 0 0 9.4 18.066v.49Zm1.2-.489a7.867 7.867 0 0 0 3.747-1.277l.314.377a8.353 8.353 0 0 1-4.061 1.39v-.49Zm4.218-3.216c-.113.117-.231.23-.353.338A6.76 6.76 0 0 0 10.6 13.54v-.49a7.245 7.245 0 0 1 4.218 1.8Zm-9.636 0a7.246 7.246 0 0 1 4.218-1.8v.49a6.759 6.759 0 0 0-3.865 1.649 6.755 6.755 0 0 1-.353-.339Zm2.61-6.324A2.292 2.292 0 0 0 9.4 10.134v.502a2.779 2.779 0 0 1-2.11-2.11h.502Zm4.918 0a2.779 2.779 0 0 1-2.11 2.11v-.502a2.292 2.292 0 0 0 1.608-1.608h.502Zm-4.918-1.2H7.29a2.779 2.779 0 0 1 2.11-2.11v.502a2.292 2.292 0 0 0-1.608 1.608Zm2.808-2.11a2.779 2.779 0 0 1 2.11 2.11h-.502A2.292 2.292 0 0 0 10.6 5.718v-.502Z"
    />
  </svg>
);

export default SvgComponent;
