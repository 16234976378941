import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
    <g clipPath="url(#a)">
      <rect width={10} height={10} x={3.425} y={3} fill="#fff" rx={5} />
      <path fill="#000" fillRule="evenodd" d="M8.425 16a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.78-9.72a.75.75 0 0 0-1.06-1.06l-3.97 3.97-1.47-1.47a.75.75 0 0 0-1.06 1.06l2 2a.75.75 0 0 0 1.06 0l4.5-4.5Z" clipRule="evenodd" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.425 0h16v16h-16z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
