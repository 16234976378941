import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M17.059 15.521a3 3 0 0 1 4.682 2.72c-1.2.42-2.474.582-3.741.479a5.995 5.995 0 0 0-6-5.97 5.995 5.995 0 0 0-5.058 2.772m11.057 3.197.001.031c0 .225-.012.447-.037.666A11.945 11.945 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.06 6.06 0 0 1 6 18.719m0 0a8.98 8.98 0 0 1-3.739-.477 3 3 0 0 1 4.681-2.72M6 18.719a5.973 5.973 0 0 1 .942-3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.249 2.249 0 1 1-4.498 0 2.249 2.249 0 0 1 4.498 0Zm-13.5 0a2.25 2.25 0 1 1-4.499 0 2.25 2.25 0 0 1 4.499 0Z" />
  </svg>
);

export default SvgComponent;
