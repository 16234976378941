import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AngleLeft } from "../icons";
import Navigation from "./Navigation";

interface AppWrapperProps {
    children: React.ReactNode | React.ReactNode[];
    // togg
}

const AppWrapper: React.FC<AppWrapperProps> = ({ children }) => {
    const [isHorizontallyCollapsed, setIsHorizontallyCollapsed] = useState<boolean>(false);
    const toggleHorizontallyCollapse = () => setIsHorizontallyCollapsed((prev) => !prev);

    return (
        <main className="bg-white h-full w-full flex items-stretch font-sans">
            <aside className={`h-full flex-shrink-0 border-r border-black/10 ${isHorizontallyCollapsed ? "w-24" : "w-72"}`}>
                <header className={`w-full h-20 border-b border-black/10 flex-shrink-0 flex px-6 items-center relative ${isHorizontallyCollapsed ? "justify-center" : "justify-between"}`}>
                    <Link to="/" className={`overflow-hidden ${!isHorizontallyCollapsed ? "w-full" : "w-8"}`}>
                        <img src="https://s3-alpha-sig.figma.com/img/c64b/c383/40f28f19ee2126c1a79092cfd7fd1f3d?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=hc71vUWwggle2auxMstfQKMFxtqaYEOmrA2G2TWCmDJ9~ahGcg7AaY4SgSEsy9X5ZxtlbCxklEENLauSm8iKpL2d3E3xPdU7pbI6NEjdXR5o-TpyDhGGD3dEjPqPU6YXs~rn8a0uOZ7SoQ~glw2elDYhg1eb26EvXR~EBMJKI6s2VrhnmD-gPT1Kpm-25TOR5XdniPTB1Eofgj~0gDBhZB4sVT4OnLS-UxhnS--~84VVYlzzJltr64pzJHwW21zkjcUe0tNvWUHZmlhr~AH~np0hDn1uKpXM1jkswQWn-OOInVkqLbx6l2yX51ka~IFoElysa4A3J3xo~ydzsoVOeQ__" alt="bingeclip" className="h-6 w-auto object-cover object-left" />
                    </Link>
                    <button onClick={toggleHorizontallyCollapse} className={`w-8 h-8 rounded-lg border-[#F6F6F6] border flex items-center justify-center bg-white ${isHorizontallyCollapsed ? "absolute -right-4" : ""}`}>
                        <AngleLeft className={`w-4 h-4 fill-current text-black transform transition-transform ${isHorizontallyCollapsed ? "rotate-180" : ""}`} />
                    </button>
                </header>
                <Navigation isHorizontallyCollapsed={isHorizontallyCollapsed} />
            </aside>
            <section className="flex-1 h-full flex flex-col">{children}</section>
        </main>
    );
};

export default AppWrapper;