import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={21} viewBox="0 0 20 21" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d="M2.241 11.107s.31 5.587 7.759 5.587c7.448 0 7.759-5.587 7.759-5.587M10 17.314v1.862M5.965 5.211a4.034 4.034 0 0 1 8.069 0v3.724a4.035 4.035 0 0 1-8.07 0V5.21Z" />
  </svg>
);

export default SvgComponent;
