import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={15} viewBox="0 0 14 15" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.3} d="M9.052 10.95 4.538 8.898m4.514-4.104L4.538 6.846m8.412-3.283a1.847 1.847 0 1 1-3.693 0 1.847 1.847 0 0 1 3.693 0ZM4.743 7.667a1.847 1.847 0 1 1-3.693 0 1.847 1.847 0 0 1 3.693 0Zm8.207 4.103a1.847 1.847 0 1 1-3.693 0 1.847 1.847 0 0 1 3.693 0Z" />
  </svg>
);

export default SvgComponent;
