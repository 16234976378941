import React from "react";
import { FeatureCard } from "../FeatureCards";

export const ProductFeature = () => {
  const FeatureData = [
    {
      title: "User & Cohort Level Personalisation",
      content:
        "Tailor your videos not just to individual users but also to specific cohorts, allowing for targeted messaging that resonates with your diverse audience.",
      source:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/personalisation_website.mp4",
    },
    {
      title: "Near Real-Time Generation",
      content:
        "Experience the efficiency of BingeClip AI with near real-time video generation. Stay agile and responsive, ensuring that your content is always fresh and relevant.",
      source:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/realtime.mp4",
    },
    {
      title: "Custom Avatars and Voice Cloning",
      content:
        "Unleash creativity with custom avatars and voice cloning. Make your videos truly unique and engaging, leaving a memorable impression on your audience.",
      source:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/avatar_cloning.mp4",
    },
    {
      title: "Accurate Information and Engaging Visuals",
      content:
        "Trust in the accuracy of information presented through engaging visuals. BingeClip AI is committed to delivering content that not only informs but captivates your viewers.",
      source:
        "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/informatiion_engaging.mp4",
    },
  ];
  return (
    <div id="product-feature" className="bg-white">
      <div className="h-[50px] bg-white"></div>
      <div className="flex flex-col text-white">
        <div className="flex flex-col gap-4 items-center justify-center w-full py-4 px-10 md:px-0">
          <div className="whitespace-nowrap text-[#01203AE5] rounded-2xl px-10 text-2xl md:text-4xl font-bold">
            PRODUCT FEATURES
          </div>
        </div>
        {FeatureData.map((item, index) => {
          return <FeatureCard {...item} index={index} key={index}/>;
        })}
      </div>
    </div>
  );
};
