import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} viewBox="0 0 13 12" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d="m10.18 6.88.88-.88a2.987 2.987 0 1 0-4.226-4.225l-.88.88M3.49 5.12 2.61 6a2.987 2.987 0 1 0 4.224 4.225l.88-.88m.705-4.93L5.25 7.586" />
  </svg>
);

export default SvgComponent;
