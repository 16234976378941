import { Menu, Transition } from "@headlessui/react";
import clsx from "clsx";
import Image from "rc-image";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { PiSignIn } from "react-icons/pi";
import UserProfileMenu from '../UserProfile/menu'
import { useAuth } from '../../context/AuthContext'
function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

interface buttonVisibility {
  visible: boolean;
  showHeaderLinks: boolean;
}

export const Navbar = (visible: buttonVisibility) => {
  const { user } = useAuth();
  const navItems = [
    {
      title: "Why BingeClip AI",
      link: "/#why-recount-ai",
    },
    {
      title: "Product Feature",
      link: "/#product-feature",
    },
    {
      title: "Use Cases",
      link: "/#customer-stories",
    },
    {
      title: "How it works",
      link: "/#how-it-works",
    },
    // {
    //   title: "Login",
    //   link: "/login",
    // },
  ];
  const baseURL = process.env.REACT_APP_BASE_URL || '/';
  // console.log("baseurl" + baseURL)
  const filteredNavItems = user ? navItems.filter(item => (item.title !== "Login" && visible.showHeaderLinks)) : navItems;
  // console.log(filteredNavItems)
  return (
    <div className="flex bg-white w-full fixed z-[20]">
      <div className="flex md:flex-row justify-between w-full items-center px-6 py-2 gap-7 ">
        <Link to={baseURL} className="flex-1">
          < Image
            src={process.env.PUBLIC_URL + "assets/logo.png"}
            alt="#"
            height={40}
          />
        </Link>
        <div className="hidden md:flex gap-6 md:gap-14 text-md text-home-page-blue font-bold ">
          {filteredNavItems.map((item, index) => {
            return (
              <a key={index} href={item.link} className="text-sm">
                {item.title}
              </a>
            );
          })}
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="md:hidden block w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z"
                fill="#000000"
              />
            </svg>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {filteredNavItems.map((item, index) => {
                  return (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <a
                          key={index}
                          href={item.link}
                          className={classNames(
                            active ? "bg-gray-100 text-black-900" : "text-black-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          {item.title}
                        </a>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
              <PiSignIn />
            </Menu.Items>
          </Transition>
        </Menu>
        {user ? (
          <UserProfileMenu />
        ) : (
            <Link
              to={"/trial"}
              className={clsx(
                "bg-[#01203AE5] text-sm hidden  rounded-full shadow-md shadow-[#4BD1A0] items-center py-1 justify-center px-6 text-white border hover:bg-[#4BD1A0] hover:border-[#01203AE5] hover:text-[#01203AE5] transition-all duration-200",
                visible.visible ? "md:flex" : "hidden"
              )}
            >
              Try for free
            </Link>
          )}
      </div>
    </div>
  );
};
