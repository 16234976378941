import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15" fill="none" {...props}>
    <path fill="current" d="m6.552 11.85-3.268.838a.307.307 0 0 1-.373-.373l.838-3.268a.307.307 0 0 1 .08-.14l6.098-6.098c.12-.12.314-.12.433 0l2.43 2.43c.12.119.12.313 0 .433l-6.098 6.097a.308.308 0 0 1-.14.08Z" />
    <path fill="current" fillRule="evenodd" d="m10.514 2.521 2.429 2.429c.3.3.3.785 0 1.085l-6.097 6.097a.767.767 0 0 1-.352.2l-3.267.839a.767.767 0 0 1-.934-.934l.839-3.267a.767.767 0 0 1 .2-.352L9.43 2.521c.3-.3.785-.3 1.085 0Zm-.542.76-5.96 5.958-.763 2.976 2.976-.764 5.959-5.959L9.972 3.28Z" clipRule="evenodd" />
    <path fill="#9368FF" fillRule="evenodd" d="M10.47 5.13a.46.46 0 0 1 0 .65L6.789 9.461a.46.46 0 0 1-.65-.65l3.68-3.681a.46.46 0 0 1 .65 0ZM4.796 9.718a.46.46 0 0 1 .65 0l.614.613a.46.46 0 0 1-.65.651l-.614-.613a.46.46 0 0 1 0-.651Z" clipRule="evenodd" />
    <path fill="current" fillRule="evenodd" d="M11.455 1.04a.46.46 0 0 1 .65 0l2.454 2.454a.46.46 0 0 1-.65.65l-2.454-2.453a.46.46 0 0 1 0-.651Z" clipRule="evenodd" />
  </svg>
);

export default SvgComponent;
