import Login from '../components/Login'
import Register from '../components/Register'
import {useState} from 'react'

const SignIn = () => {
    const [IsSignIn, setIsSignIn] = useState(true)
    const updateIsSignIn = (newState: boolean) => {
        setIsSignIn(newState)
    }
    return (
        IsSignIn ? <Login updateState={updateIsSignIn}></Login> : <Register updateState={updateIsSignIn}></Register>
    )
};

export default SignIn;
