import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path fill="current" fillRule="evenodd" d="M.834 4.668A3.833 3.833 0 0 1 4.668.834h6.665a3.833 3.833 0 0 1 3.833 3.834v6.666a3.833 3.833 0 0 1-3.834 3.833H4.667a3.833 3.833 0 0 1-3.833-3.834V4.668Zm3.834-2.834a2.833 2.833 0 0 0-2.834 2.834v6.665a2.833 2.833 0 0 0 2.833 2.833h6.665a2.833 2.833 0 0 0 2.834-2.832V4.668a2.833 2.833 0 0 0-2.833-2.833H4.668Z" clipRule="evenodd" />
    <path fill="current" fillRule="evenodd" d="M7.415 6.04c-.138.123-.248.318-.248.627a.5.5 0 0 1-1 0c0-.58.222-1.052.584-1.374A1.88 1.88 0 0 1 8 4.833c.44 0 .896.146 1.249.46.362.322.584.794.584 1.374 0 .428-.11.754-.302 1.021a2.599 2.599 0 0 1-.484.48l-.06.05c-.155.128-.261.232-.34.376-.076.14-.147.36-.147.74a.5.5 0 0 1-1 0c0-.51.096-.901.27-1.22.171-.314.398-.516.577-.665l.08-.067c.144-.118.226-.186.292-.278.058-.08.114-.198.114-.437 0-.309-.11-.504-.249-.627A.882.882 0 0 0 8 5.833a.882.882 0 0 0-.585.207Z" clipRule="evenodd" />
    <path fill="current" d="M8.667 10.667a.667.667 0 1 1-1.334 0 .667.667 0 0 1 1.334 0Z" />
  </svg>
);

export default SvgComponent;
