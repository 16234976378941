import React from "react";
import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { AI, DownloadBig, Edit, ThumbsDown, ThumbsUp, WhatsApp } from "../components/icons";
import AppWrapper from "../components/ui/AppWrapper";
import UserProfileMenu from "../components/UserProfile/menu"
interface VideoPreviewPageProps { }

const VideoPreviewPage: React.FC<VideoPreviewPageProps> = () => {
  const { search } = useLocation();
  const selectedAvatar = new URLSearchParams(search).get("avatar_id");
  const videoSource = useMemo(() => {
    let videoSource: string = "";
    if (selectedAvatar === "1") {
      videoSource = "https://d3o847c916r6no.cloudfront.net/ws_assets/video_output_hi.mp4";
    } else if (selectedAvatar === "2") {
      videoSource = "https://d3o847c916r6no.cloudfront.net/ws_assets/personalisation_male_hi.mp4";
    } else {
      videoSource = "https://d3o847c916r6no.cloudfront.net/ws_assets/video_output_hi.mp4";
    }
    return videoSource;
  }, [selectedAvatar]);

  return (
    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Preview</h1>
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col flex-1 items-center justify-center">
            <div className="flex flex-col items-center gap-4 py-12">
              <div className="flex items-center justify-between w-full">
                <h2 className="text-2xl font-semibold text-[#1F2633]">Video Preview</h2>
                <button className="border border-[#B0CBFF] rounded-lg flex items-center gap-x-3 py-2 px-4">
                  <WhatsApp className="w-4 h-4 fill-current text-[#1F2633]" />
                  <span className="text-[#475467] text-xs font-medium">Share</span>
                </button>
              </div>
              <video className="w-[520px] aspect-video rounded-xl" controls>
                <source src={videoSource} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center divide-x divide-[#D6D6D6] border border-[#D6D6D6] bg-white rounded-full">
                  <button className="py-2 px-4 flex items-center justify-center">
                    <ThumbsUp className="w-5 h-5 fill-current text-[#1F2633]" />
                  </button>
                  <button className="py-1 px-4 flex items-center justify-center">
                    <ThumbsDown className="w-4 h-4 fill-current text-[#1F2633]" />
                  </button>
                </div>
                <div className="flex items-center gap-3">
                  <Link to="/video/new/edit" className="border border-[#9E77FF] rounded-lg flex items-center gap-x-3 py-2 px-4">
                    <Edit className="w-4 h-4 fill-current text-[#2474FF]" />
                    <span className="text-[#2474FF] text-xs font-medium">Edit Video</span>
                  </Link>
                  <button className="border border-[#9E77FF] bg-[#9E77FF] rounded-lg flex items-center gap-x-3 py-2 px-4">
                    <DownloadBig className="w-4 h-4 fill-current text-white" />
                    <span className="text-white text-xs font-medium">Download</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-6 mt-6 relative pb-8">
            <div className="w-[786px] p-6 rounded-xl bg-white border border-[#F0F2F5] shadow-2xl">
              <textarea className="w-full h-28 border border-[#F0F2F5] rounded-lg p-3 resize-none" placeholder="Write something here..." />
              <div className="flex items-center justify-end gap-6 mt-4">
                <p className="border border-[#EBEEF2] rounded-full text-xs font-bold text-[#606C80] px-2 py-1">60/25000</p>
                <button className="flex items-center gap-3 p-3 rounded-lg bg-black border border-[#292D32]">
                  <AI className="w-4 h-4 fill-current text-white" />
                  <span className="text-white text-sm font-medium">Generate Video</span>
                </button>
              </div>
            </div>
          </div>
        </article>
      </section>
    </AppWrapper>
  );
};

export default VideoPreviewPage;
