import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={18} viewBox="0 0 17 18" fill="none" {...props}>
    <path fill="current" fillRule="evenodd" d="M4.046 2.693a.852.852 0 0 0-.852.853v10.908c0 .471.381.852.852.852h9.545c.47 0 .852-.381.852-.852v-7.97l-3.79-3.79H4.045Zm-1.875.853c0-1.036.84-1.875 1.875-1.875h6.677c.226 0 .443.09.602.25l3.892 3.89c.16.16.25.377.25.603v8.04c0 1.036-.84 1.875-1.876 1.875H4.046a1.875 1.875 0 0 1-1.875-1.875V3.546Z" clipRule="evenodd" />
    <path fill="current" fillRule="evenodd" d="M10.353 2.347a.58.58 0 0 1 .989-.41L15.2 5.795a.58.58 0 0 1-.41.99h-3.858a.58.58 0 0 1-.58-.58V2.347Zm1.022 1.07v2.345h2.345l-2.345-2.345ZM6.022 10.1c.2-.2.524-.2.723 0l1.127 1.126 3.049-2.614a.511.511 0 0 1 .665.776l-3.36 2.881a.58.58 0 0 1-.787-.03l-1.417-1.416c-.2-.2-.2-.524 0-.723Z" clipRule="evenodd" />
  </svg>
);

export default SvgComponent;
