import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AngleUp } from "../icons";

interface NavMenuBlockProps {
  title: string;
  icon: (props: any) => React.ReactNode;
  links: { title: string; to: string }[];
  isHorizontallyCollapsed?: boolean;
}

const NavMenuBlock: React.FC<NavMenuBlockProps> = ({ title, icon, links, isHorizontallyCollapsed }) => {
  const [isCollapsed, setCollapsed] = useState<boolean>(false);

  const toggleCollapse = () => setCollapsed((prev) => !prev);

  return (
    <div className="flex flex-col gap-3">
      <button onClick={!isHorizontallyCollapsed ? toggleCollapse : undefined} className={`h-12 px-3 w-full rounded-lg flex items-center justify-between ${isHorizontallyCollapsed ? "" : "bg-[#f6f6f6]"}`}>
        <div className="flex items-center gap-3">
          {icon({ className: "w-6 h-6 fill-current text-black" })}
          {!isHorizontallyCollapsed && <span className="text-sm">{title}</span>}
        </div>
        {!isHorizontallyCollapsed && <AngleUp className={`w-4 h-4 fill-current text-[#757575] transform transition-transform ${isCollapsed ? "rotate-180" : ""}`} />}
      </button>
      {!isHorizontallyCollapsed && !isCollapsed && (
        <div className="relative flex flex-col gap-1 pl-10">
          <div className="absolute top-0 left-6 w-1 h-[calc(100%-12px)] bg-[#F6F6F6]"></div>
          {links.map((link, index) => (
            <NavLink key={index} to={link.to} className={({ isActive }) => ["block text-[#757575] text-xs py-2 px-3 hover:text-black hover:bg-[#F6F6F6] rounded-lg cursor-pointer", isActive ? "text-black bg-[#F6F6F6]" : ""].join(" ")}>
              {link.title}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );
};

export default NavMenuBlock;
