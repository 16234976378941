import React from "react";

interface TabsProps {
  value: string;
  options: { id: string; label: string; icon: (props: any) => React.ReactNode }[];
  onChange: (id: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ value, options, onChange }) => {
  return (
    <div className="flex items-center p-2 bg-[#F0F2F5] rounded-lg">
      {options.map((tab) => (
        <button key={tab.id} onClick={onChange.bind(null, tab.id)} className={`flex items-center gap-x-2 px-4 py-2 border border-[#F0F2F5] rounded-md ${value === tab.id ? "bg-white shadow-sm" : ""}`}>
          {tab.icon({ className: "w-4 h-4 fill-current text-[#19213D]" })}
          <span className="text-xs text-[#19213D]">{tab.label}</span>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
