import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} viewBox="0 0 13 12" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d="m1.15 8.814 1.932-2.458a1.407 1.407 0 0 1 2.173-.047l1.699 1.977M5.487 6.58C6.217 5.65 7.233 4.336 7.3 4.25a1.407 1.407 0 0 1 2.177-.052l1.697 1.978M2.557 11.1h7.386c.777 0 1.407-.63 1.407-1.407V2.307C11.35 1.53 10.72.9 9.944.9H2.557C1.78.9 1.15 1.53 1.15 2.307v7.386c0 .777.63 1.407 1.407 1.407Z" />
  </svg>
);

export default SvgComponent;
