import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path fill="current" fillRule="evenodd" d="M2.732.566c-.215 0-.426.055-.613.159a1.192 1.192 0 0 0-.65 1.04V9.34a1.192 1.192 0 0 0 .65 1.041 1.264 1.264 0 0 0 1.247-.013l6.12-3.784a1.17 1.17 0 0 0-.001-2.09L3.365.735a1.263 1.263 0 0 0-.633-.17Z" clipRule="evenodd" />
  </svg>
);

export default SvgComponent;
