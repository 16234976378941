import React, { useState } from 'react';
import { Menu, Avatar, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react';
import { FaHistory, FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import {
    Square3Stack3DIcon,
} from "@heroicons/react/24/solid";

const UserProfileMenu = () => {
    const { setUser, logout } = useAuth();
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    const handleOpen = () => setOpen(prev => !prev);
    const handleClose = () => setOpen(false);

    const handleLogout = async () => {
        try {
            const success = await logout()
            if (success) {
                handleClose();
                setUser(null);
                navigate("/");
            } else {
                console.log("Error logging out!!")
            }
        }
        catch (error) {
            console.log("error logging out")
        }
    };

    interface UserMenuItem {
        placeholder: string;
        url: string;
        icon: React.ElementType;
        display: string;
    }
    const handleMenuItemClick = (url: string): void => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
    const baseURL = process.env.REACT_APP_BASE_URL
    const userMenuItems: UserMenuItem[] = [
        { placeholder: "assets", url: baseURL + 'media-library', icon: FaUserCircle, display: "Media" },
        { placeholder: "script-to-video", url: baseURL + 'video/new', icon: Square3Stack3DIcon, display: "Create Video" },
        { placeholder: "history", url: baseURL + 'template/saved', icon: FaHistory, display: "Saved Template" },
    ]

    return (
        <Menu open={open} handler={handleOpen} placement="bottom" >
            <MenuHandler>
                <Avatar
                    size="md"
                    placeholder="avatar"
                    variant="circular"
                    alt="tania andrew"
                    className="cursor-pointer"
                    src="https://bingeclip.ai/assets/customer-avatar/avatar.svg"
                />
            </MenuHandler>
            <MenuList placeholder="user-toggle" className="mr-1 content-end">
                {userMenuItems.map((item, index) => (
                    <MenuItem key={index} placeholder={item.placeholder} className="flex items-center gap-2" onClick={() => handleMenuItemClick(item.url)}>
                        <item.icon className="h-5 w-5" />
                        {item.display}
                    </MenuItem>
                ))}
                <MenuItem placeholder="logout" className="flex items-center gap-2" onClick={handleLogout}>
                    <FaSignOutAlt className="h-5 w-5" />
            Logout
          </MenuItem>
            </MenuList>
        </Menu >
    );
};

export default UserProfileMenu;
