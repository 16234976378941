import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={13} viewBox="0 0 13 13" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d="M11.7 11.7 9.01 9.01M1.3 5.783a4.483 4.483 0 1 1 8.965 0 4.483 4.483 0 0 1-8.965 0Z" />
  </svg>
);

export default SvgComponent;
