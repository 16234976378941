import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
    <path fill="current" fillRule="evenodd" d="M1.472 4.668A3.833 3.833 0 0 1 5.306.835h6.665a3.833 3.833 0 0 1 3.833 3.834v6.664a3.833 3.833 0 0 1-3.834 3.833H5.305a3.833 3.833 0 0 1-3.833-3.834V4.668Zm3.834-2.833a2.833 2.833 0 0 0-2.834 2.833v6.665a2.833 2.833 0 0 0 2.833 2.833h6.665a2.833 2.833 0 0 0 2.833-2.833V4.67a2.833 2.833 0 0 0-2.832-2.834H5.306Z" clipRule="evenodd" />
    <path fill="current" d="M10.736 8.282 6.75 10.277c-.182.09-.386-.059-.386-.282v-3.99c0-.223.204-.373.386-.282l3.987 1.995c.218.109.218.455 0 .564Z" />
    <path fill="current" fillRule="evenodd" d="M5.951 6.005c0-.558.511-.932.965-.705l3.988 1.995c.543.271.543 1.139 0 1.41L6.916 10.7c-.454.227-.965-.147-.965-.705v-3.99Zm.823.238v3.514L10.287 8 6.774 6.243Z" clipRule="evenodd" />
  </svg>
);

export default SvgComponent;
