import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import { useState } from 'react';
import { Link } from "react-router-dom";
import { AI, FilledFile, LineFile } from "../components/icons";
import AppWrapper from "../components/ui/AppWrapper";
import UserProfileMenu from "../components/UserProfile/menu"
interface NewCampaignPageProps { }

const FullScreenLoader: React.FC = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the loader is on top of other content
    }}
  >
    <div className="text-black font-semibold text-lg">
      Sending to Whatsapp... &nbsp; &nbsp;&nbsp;&nbsp;
    </div>
    <CircularProgress color="inherit" />
  </Box>
);


const NewCampaignPage: React.FC<NewCampaignPageProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default navigation
    const url = event.currentTarget.href;
    setIsLoading(true);
    // Set a delay before navigating
    setTimeout(() => {
      window.location.href = url;
      // setIsLoading(false);
    }, 10000); // 2000ms = 2 seconds delay
  };
  return (
    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Run Campaign</h1>
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col flex-1 items-center relative mt-12">
            <div className="flex items-center gap-x-4 w-[786px] p-6 rounded-xl bg-white border border-[#F0F2F5] shadow-2xl">
              <input className="flex-1 focus:outline-none rounded-lg resize-none text-lg font-medium text-[#19213D] placeholder:text-[#19213D]" placeholder="Enter Name" /> &nbsp;

              {/* <Link to="/video/new/preview" className="flex items-center gap-3 p-3 rounded-lg bg-[#F0F2F5] border border-[#CCC2DC]">
                <span className="text-black text-sm font-medium"></span>
                <RightArrow className="w-4 h-4 fill-current text-black" />
              </Link> */}
            </div>
            <div> </div>
            <div className="flex items-center gap-x-4 w-[786px] p-6 rounded-xl bg-white border border-[#F0F2F5] shadow-2xl">
              <input className="flex-1 focus:outline-none rounded-lg resize-none text-lg font-medium text-[#19213D] placeholder:text-[#19213D]" placeholder="Enter Contact Number" /> &nbsp;

              {/* <Link to="/video/new/preview" className="flex items-center gap-3 p-3 rounded-lg bg-[#F0F2F5] border border-[#CCC2DC]">
                <span className="text-black text-sm font-medium"></span>
                <RightArrow className="w-4 h-4 fill-current text-black" />
              </Link> */}
            </div>
          </div>
          <div className="flex flex-col items-center mt-6 relative pb-8">
            <div className="flex flex-col items-center w-[520px] bg-white border border-[#F0F2F5] shadow-2xl rounded-2xl p-6">
              <button className="bg-[#F6FAFF] w-12 h-12 flex items-center justify-center rounded-lg">
                <LineFile className="w-6 h-6 fill-current" />
              </button>
              <p className="text-sm text-[#19213D] font-medium mt-4">Drag and drop your files here</p>
              <p className="text-sm text-[#666F8D] font-medium my-2">or</p>
              <Link to="/template/saved/run" className="flex items-center gap-3 px-4 py-2 rounded-lg bg-black border border-[#292D32]">
                <FilledFile className="w-3 h-3 fill-current text-white" />
                <span className="text-white text-xs font-medium">Choose your files</span>
              </Link>
            </div>
            <div className="flex mb-8 items-center justify-center mt-12">
              {!isLoading && (
                <a href="/video/new/preview" onClick={handleClick} className="flex items-center gap-3 p-3 rounded-lg bg-black border border-[#292D32]">
                  <AI className="w-4 h-4 fill-current text-white" />
                  <span className="text-white text-sm font-medium">Run Campaign</span>
                </a>
              )};
              {isLoading && <FullScreenLoader />} {/* Show full-screen loader */}
            </div>
          </div>
        </article>
      </section>
    </AppWrapper>
  );
};

export default NewCampaignPage;
