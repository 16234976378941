import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';

interface AuthUser {
    pk: number;
    email: string;
    firstName: string;
    lastName: string;
}

interface AuthContextType {
    user: AuthUser | null;
    setUser: (user: AuthUser | null) => void;
    // isloggedin: boolean;
    // setIsloggedin: (isloggedin: boolean) => void;
    login: (email: string, password: string) => Promise<boolean>;
    logout: () => Promise<boolean>;
    // verifyToken: () => Promise<void>;
    // refreshAccessToken: () => Promise<void>;
    // fetchUserDetails: () => Promise<void>;
    loading: boolean;
}

interface LoginResponse {
    user: {
        pk: number;
        email: string;
        firstName: string;
        lastName: string;
    };
    access: string;
    refresh: string;
}

interface LogoutResponse {
    response: boolean;
}

function getCookie(name: String) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) throw new Error('useAuth must be used within an AuthProvider');
    return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const [user, setUser] = useState<AuthUser | null>(null);
    // const [isloggedin, setIsloggedin] = useState(Boolean)
    const [loading, setLoading] = useState(true);

    const fetchUserDetails = async () => {
        try {
            const getUserURL = process.env.REACT_APP_AUTH_BASE_URL + 'auth/user'
            const response = await axios.get(getUserURL, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken') ?? ''
                }
            });
            if (response.status === 200) {
                setUser({
                    pk: response.data.pk,
                    email: response.data.email,
                    firstName: response.data.first_name,
                    lastName: response.data.last_name
                });
            } else {
                throw new Error('Failed to fetch user details');
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            setUser(null);
            // setIsloggedin(false);
        }
    };


    const refreshAccessToken = async () => {
        const refresh_data = {
            "refresh": ""
        }
        // console.log("refresh token api : " + process.env.REACT_APP_AUTH_BASE_URL)
        const refreshTokenURL = process.env.REACT_APP_AUTH_BASE_URL + 'auth/token/refresh';
        try {
            await axios.post(refreshTokenURL, refresh_data, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCookie('csrftoken') ?? ''
                }
            });
            await verifyToken();
        } catch (error) {
            console.error('Failed to refresh token:', error);
            setUser(null); // Clear user data if refresh fails
            // setIsloggedin(false)
        }
    };

    const verifyToken = async () => {
        try {
            const verify_token = {
                "token": ""
            }
            const verifyTokenURL = process.env.REACT_APP_AUTH_BASE_URL + 'auth/token/verify';
            const response = await axios.post(verifyTokenURL, verify_token, {
                withCredentials: true,
                headers: {
                    'X-CSRFToken': getCookie('csrftoken') ?? ''
                }
            });

            if (response.status === 200) {
                // console.log('token verified')
                // setIsloggedin(true)
                await fetchUserDetails()
            } else {
                // If the token verification fails, try refreshing the token
                await refreshAccessToken();
                // setIsloggedin(true)
            }
        } catch (error) {
            console.error('Token verification failed:', error);
            await refreshAccessToken();
            // setIsloggedin(false)
        }
        setLoading(false);
    };


    useEffect(() => {
        const checkAuthStatus = async () => {
            // console.log(process.env)
            try {
                await verifyToken();
            } catch (error) {
                console.error('Token verification failed:', error);
            }
            setLoading(false);
        };
        checkAuthStatus();
    }, []);

    const login = async (email: String, password: String): Promise<boolean> => {
        const userLoginURL = process.env.REACT_APP_AUTH_BASE_URL + 'auth/login';
        const request_data = {
            "email": email,
            "password": password
        };
        try {
            const response = await axios.post<LoginResponse>(userLoginURL, request_data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken') ?? ''
                }
            });
            // console.log(response.status)
            if (response.status === 200) {
                // console.log(response.data)
                setUser({
                    pk: response.data.user.pk,
                    email: response.data.user.email,
                    firstName: response.data.user.firstName,
                    lastName: response.data.user.lastName
                });
                // setIsloggedin(true)
            } else {
                console.log("Unable to catch response success code")
                // setIsloggedin(false)
                return false
            }
            return true;
        } catch (error) {
            console.error('Login failed:', error);
            // setIsloggedin(false)
            return false;
        }
    };


    const logout = async (): Promise<boolean> => {
        const logoutURL = process.env.REACT_APP_AUTH_BASE_URL + 'auth/logout';
        const request_data = {
            "refresh": "",
        };
        try {
            const response = await axios.post<LogoutResponse>(logoutURL, request_data, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken') ?? ''
                }
            });
            // console.log(response.status)
            if (response.status === 200 || response.status === 208) {
                // setIsloggedin(false)
                setUser(null)
                return true
            } else {
                console.log("Unable to catch response success code")
                // setIsloggedin(true)
                return false
            }
        } catch (error) {
            console.error('Login failed:', error);
            // setIsloggedin(true)
            return false;
        }
    };

    return (
        <AuthContext.Provider value={{ user, setUser, loading, login, logout }}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
