import React from "react";
import { DownloadBig, More, Share } from "../icons";

interface VideoHistoryCardProps {
  id: string;
  src: string;
  prompt: string;
}


const VideoHistoryCard: React.FC<VideoHistoryCardProps> = (VideoHistoryCardProps) => {
  return (
    <div className="flex flex-col">
      <img src={VideoHistoryCardProps.src} alt="" />
      <div className="flex items-start flex-1 pt-4 gap-3">
        <p className="text-sm line-clamp-2 flex-1">
          Prompt: <span className="text-[#666F8D]">{VideoHistoryCardProps.prompt}</span>
        </p>
        <div className="flex-shrink-0 flex items-center gap-x-2">
          <button>
            <Share className="w-4 h-4 fill-current" />
          </button>
          <button>
            <DownloadBig className="w-4 h-4 fill-current" />
          </button>
          <button>
            <More className="w-4 h-4 fill-current" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoHistoryCard;
