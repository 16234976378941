import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.25} d="M2.25 10.541v1.438a1.437 1.437 0 0 0 1.438 1.437h8.624a1.437 1.437 0 0 0 1.438-1.438v-1.437m-2.875-2.875L8 10.541m0 0L5.125 7.666M8 10.541V1.916" />
  </svg>
);

export default SvgComponent;
