import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={17} height={16} viewBox="0 0 17 16" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.322} d="M15.22 7.723c0 .904-1.582 5.652-6.556 5.652S2.107 8.627 2.107 7.723c0-.905 1.583-5.653 6.557-5.653s6.556 4.748 6.556 5.653Z" />
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.322} d="M8.664 9.757a2.035 2.035 0 1 0 0-4.07 2.035 2.035 0 0 0 0 4.07Z" />
  </svg>
);

export default SvgComponent;
