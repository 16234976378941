import React from "react";
import { DownloadBig, Eye } from "../icons";

interface MediaProps {
  id: string;
  src: string;
}

const Media: React.FC<MediaProps> = (MediaProps) => {
  return (
    <div className="relative w-full aspect-square rounded-lg bg-[#F0F2F5]">
      <img src={MediaProps.src} alt="media" className="object-cover w-full h-full rounded-lg" />
      <div className="absolute inset-0 flex items-end justify-end gap-1 bottom-3 right-3">
        <button className="flex items-center gap-2 p-2 border border-[#F0F2F5] bg-[#F7F8FA] rounded-lg shadow-sm">
          <Eye className="w-4 h-4 fill-current text-black" />
        </button>
        <button className="flex items-center gap-2 p-2 border border-[#F0F2F5] bg-[#F7F8FA] rounded-lg shadow-sm">
          <DownloadBig className="w-4 h-4 fill-current text-black" />
        </button>
      </div>
    </div>
  );
};

export default Media;
