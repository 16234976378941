import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
    <path fill="current" d="M.93 8.264a.839.839 0 0 1 1.678 0v7.55a.839.839 0 1 1-1.678 0v-7.55Z" />
    <path
      fill="current"
      fillRule="evenodd"
      d="M.301 8.269a1.468 1.468 0 0 1 2.936 0v7.55a1.468 1.468 0 0 1-2.936 0v-7.55Zm1.468-.21a.21.21 0 0 0-.21.21v7.55a.21.21 0 0 0 .42 0v-7.55a.21.21 0 0 0-.21-.21ZM11.266.668c.173-.278.487-.58.989-.58.63 0 1.059.436 1.274.968.21.518.27 1.215.191 2.05-.065.689-.291 1.556-.542 2.345-.13.406-.27.804-.405 1.161h3.257c.023 0 .046.001.069.004.626.07 1.2.23 1.612.69.393.439.54 1.045.621 1.724.003.025.005.05.005.075v2.797c0 1.036-.34 2.516-.833 3.731-.247.61-.548 1.194-.893 1.636-.32.41-.798.854-1.42.854H9.319v-.629l-.001.63h-.005l-.008-.001h-.018a1.398 1.398 0 0 1-.211-.022c-.128-.02-.3-.06-.5-.133-.403-.15-.91-.438-1.403-.985-.408-.454-1.166-.638-1.702-.7-.569-.064-.976-.564-.976-1.12V8.682c0-.585.464-1.133 1.114-1.103.484.022.794-.02 1.004-.109.179-.076.328-.205.454-.486a.629.629 0 0 1 .035-.065c.45-.753.856-1.206 1.236-1.564a13.6 13.6 0 0 1 .51-.454 5.15 5.15 0 0 0 .423-.41 7.741 7.741 0 0 0 1.7-3.087l.005-.018c.054-.183.14-.474.29-.717Zm.996.827a4.455 4.455 0 0 0-.085.265 8.997 8.997 0 0 1-1.971 3.572l-.001.001c-.18.2-.358.362-.525.51l-.145.127c-.114.1-.22.193-.335.3-.297.28-.622.638-1.002 1.266-.241.517-.602.882-1.092 1.09-.413.177-.876.223-1.352.215v6.21c.606.084 1.668.327 2.355 1.09.346.385.678.563.904.647a1.39 1.39 0 0 0 .316.077h5.862c.006 0 .157-.022.427-.37.246-.314.495-.78.72-1.334.451-1.115.74-2.431.74-3.26V9.145c-.077-.617-.19-.871-.304-.999-.096-.107-.276-.215-.78-.275h-4.159a.63.63 0 0 1-.573-.887c.198-.441.475-1.157.717-1.914.244-.766.437-1.53.488-2.08.07-.739-.002-1.207-.104-1.46a.711.711 0 0 0-.064-.126 1.36 1.36 0 0 0-.037.092Z"
      clipRule="evenodd"
    />
  </svg>
);

export default SvgComponent;
