import React from "react";
import { VideoLayout } from "../VideoLayout";
import clsx from "clsx";

export interface FeatureItem {
  title: string;
  content: string;
  source: string;  
}

interface FeatureCardProps extends FeatureItem {
  index: number;
}

export const FeatureCard = (obj: FeatureCardProps) => {
  return (
    <React.Fragment>
      <div
        className={clsx(
          "flex flex-col-reverse md:flex-row md:w-[50%] items-center md:items-start gap-4 md:gap-20 mx-auto py-6 md:py-16",
          obj.index % 2 !== 0 && "flex-col-reverse md:flex-row-reverse"
        )}
      >
        <div className="flex flex-col gap-3 md:gap-10 w-[70%] md:w-[50%]">
          <div className="text-[#01203AE5] text-xl md:text-3xl font-bold">
            {obj.title}
          </div>
          <div className="text-md md:text-lg text-black">{obj.content}</div>
        </div>
        <div className="w-[70%] md:w-[50%]">
          <VideoLayout className={"w-full"} source={obj.source} />
        </div>
      </div>
      
    </React.Fragment>
  );
};
