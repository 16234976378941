import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={15} viewBox="0 0 14 15" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" d="M6.784 11.982 1.75 7.176m0 0L6.784 2.37M1.75 7.176h10.5" />
  </svg>
);

export default SvgComponent;
