import React from "react";
import { Search } from "../icons";

interface SearchInputProps {
  placeholder?: string;
  className?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, className }) => {
  return (
    <div className={`flex items-center bg-white border overflow-hidden border-[#F0F2F5] justify-between w-full p-3 rounded-lg gap-x-2 ${className}`}>
      <Search className="w-4 h-4 fill-current text-[#666F8D] flex-shrink-0" />
      <input type="text" className="flex-1" placeholder={placeholder} />
    </div>
  );
};

export default SearchInput;
