import React from "react";
import { PrimaryCard } from "../Cards";

export const ValueProps = () => {
  const CardData = [
    {
      logo: "./assets/recount-icons/increase-market-conversion-rate-1.svg",
      theme: "bg-[#EDEDED]",
      color: "black",
      title: "30-40% assured CAC Reduction",
      content:
        "",
    },
    {
      logo: "./assets/recount-icons/woman-consultant-in-headphones-working-at-computer-1.svg",
      theme: "bg-[#4BD1A0]",
      color: "black",
      title: "Caters Inbound on Calls, Online, and at Centers",
      content:
        "",
    },
  ];

  return (
    <div id="why-recount-ai">
      <div className="w-[100%] mx-auto flex flex-wrap md:justify-evenly justify-around">
        <div className="flex flex-col gap-1 items-center text-white justify-center w-full py-10 px-10 md:px-0">
          {/* <div className="whitespace-nowrap rounded-2xl py-4 px-10 text-2xl md:text-4xl font-bold ">
            WHY RECOUNT AI?
          </div> */}
        </div>
        <div className="flex flex-wrap md:justify-evenly justify-around w-full">
          {CardData.map((item, index) => {
            // console.log(item)
            return <PrimaryCard {...item} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};
