import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12} viewBox="0 0 13 12" fill="none" {...props}>
    <path fill="current" d="M7.704 4.593a.535.535 0 0 1-.528-.528V.9H3.483a1.407 1.407 0 0 0-1.407 1.407v7.386A1.407 1.407 0 0 0 3.483 11.1h5.98a1.407 1.407 0 0 0 1.406-1.407v-5.1H7.704Z" />
    <path fill="current" d="M8.309 3.362V1.111a.169.169 0 0 1 .295-.12l2.265 2.244a.176.176 0 0 1-.126.303H8.485a.176.176 0 0 1-.176-.176Z" />
  </svg>
);

export default SvgComponent;
