import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { AI, LineFile, LineFileCheck, LineQuestion, LineVideo } from "../components/icons";
import AppWrapper from "../components/ui/AppWrapper";
// import { useNavigate } from 'react-router-dom';
import UserProfileMenu from "../components/UserProfile/menu"
interface NewVideoPageProps { }

const FullScreenLoader: React.FC = () => (
  <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Ensure the loader is on top of other content
    }}
  >
    <div className="text-black font-semibold text-lg">
      Turning Your Script into Video Magic… Just a Moment! &nbsp; &nbsp;&nbsp;&nbsp;
    </div>
    <CircularProgress color="inherit" />
  </Box>
);

const NewVideoPage: React.FC<NewVideoPageProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault(); // Prevent the default navigation
    const url = event.currentTarget.href;
    setIsLoading(true);
    // Set a delay before navigating
    setTimeout(() => {
      window.location.href = url;
      // setIsLoading(false);
    }, 10000); // 2000ms = 2 seconds delay
  };

  return (
    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Generate Video</h1>
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col flex-1 items-center relative justify-center py-12">
            <div className="w-[786px] p-6 rounded-xl bg-white border border-[#F0F2F5] shadow-2xl">
              <textarea className="w-full h-64 border focus:outline-none border-[#F0F2F5] rounded-lg p-3 resize-none" placeholder="Write something here..." />
              <div className="flex items-center justify-end gap-6 mt-4">
                <p className="border border-[#EBEEF2] rounded-full text-xs font-bold text-[#606C80] px-2 py-1">60/25000</p>
                {!isLoading && (
                  <a href="/video/new/preview" onClick={handleClick} className="flex items-center gap-3 p-3 rounded-lg bg-black border border-[#292D32]">
                    <AI className="w-4 h-4 fill-current text-white" />
                    <span className="text-white text-sm font-medium">Generate Video</span>
                  </a>
                )}
                {isLoading && <FullScreenLoader />} {/* Show full-screen loader */}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center gap-6 mt-6 relative pb-8">
            <h2 className="text-2xl font-semibold text-[#1F2633]">Scripting Workflows</h2>
            <div className="flex items-center bg-white border border-[#F0F2F5] shadow-2xl rounded-2xl p-6 gap-4">
              <button className="flex items-center gap-3 p-3 rounded-lg bg-[#D0D5DD]" disabled>
                <LineQuestion className="w-4 h-4 fill-current text-[#98A2B3]" />
                <span className="text-[#98A2B3] text-sm font-medium">Ask Knowledge Base</span>
              </button>
              <button className="flex items-center gap-3 p-3 rounded-lg bg-[#D0D5DD]" disabled>
                <LineFile className="w-4 h-4 fill-current text-[#98A2B3]" />
                <span className="text-[#98A2B3] text-sm font-medium">Ask A Document</span>
              </button>
              <button className="flex items-center gap-3 p-3 rounded-lg bg-[#D0D5DD]" disabled>
                <LineVideo className="w-4 h-4 fill-current text-[#98A2B3]" />
                <span className="text-[#98A2B3] text-sm font-medium">Script a Video</span>
              </button>
              <button className="flex items-center gap-3 p-3 rounded-lg bg-[#D0D5DD]" disabled>
                <LineFileCheck className="w-4 h-4 fill-current text-[#98A2B3]" />
                <span className="text-[#98A2B3] text-sm font-medium">Report a Video</span>
              </button>
            </div>
          </div>
        </article>
      </section>
    </AppWrapper >
  );
};

export default NewVideoPage;
