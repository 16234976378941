import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={19} viewBox="0 0 19 19" fill="none" {...props}>
    <g clipPath="url(#a)">
      <path fill="#E0E0E0" d="M16.117 3.237a9.002 9.002 0 0 0-11.805-.775A8.928 8.928 0 0 0 .97 7.804a8.899 8.899 0 0 0 1.024 6.21L.722 18.638l4.756-1.241a8.999 8.999 0 0 0 4.288 1.087h.004a9.001 9.001 0 0 0 4.984-1.506 8.942 8.942 0 0 0 3.305-4.007 8.896 8.896 0 0 0 .511-5.16 8.92 8.92 0 0 0-2.453-4.575ZM9.77 16.977h-.003a7.474 7.474 0 0 1-3.796-1.035l-.272-.16-2.823.736.753-2.74-.177-.28a7.398 7.398 0 0 1 .9-9.05 7.486 7.486 0 0 1 9.026-1.394 7.437 7.437 0 0 1 3.24 3.561 7.394 7.394 0 0 1 .372 4.791 7.423 7.423 0 0 1-2.654 4.014 7.48 7.48 0 0 1-4.567 1.556Z" />
      <path fill="url(#b)" d="m1.133 18.125 1.215-4.416A8.493 8.493 0 0 1 3.716 3.42a8.595 8.595 0 0 1 10.336-1.35 8.537 8.537 0 0 1 3.627 4.12 8.49 8.49 0 0 1 .36 5.468 8.523 8.523 0 0 1-3.059 4.556 8.587 8.587 0 0 1-5.212 1.762h-.004a8.59 8.59 0 0 1-4.09-1.037l-4.54 1.184v.001Z" />
      <path fill="#fff" fillRule="evenodd" d="M7.552 5.756c-.166-.367-.34-.375-.498-.381-.13-.006-.277-.005-.425-.005a.818.818 0 0 0-.591.276 2.473 2.473 0 0 0-.776 1.84c0 1.085.795 2.134.905 2.282.111.147 1.533 2.445 3.786 3.33 1.872.735 2.253.588 2.66.552.406-.037 1.31-.534 1.495-1.05.185-.514.185-.956.13-1.048-.056-.092-.203-.147-.425-.258-.222-.11-1.311-.644-1.514-.717-.204-.074-.351-.11-.5.11-.147.221-.571.718-.7.865-.13.148-.26.166-.481.056a6.066 6.066 0 0 1-1.782-1.095 6.65 6.65 0 0 1-1.233-1.528c-.13-.22-.014-.34.097-.45.1-.1.222-.258.333-.387.09-.111.165-.235.221-.368a.405.405 0 0 0-.018-.386c-.056-.11-.487-1.202-.684-1.638Z" clipRule="evenodd" />
      <path fill="#fff" d="M16.043 3.209a8.897 8.897 0 0 0-11.671-.77A8.825 8.825 0 0 0 1.07 7.72a8.796 8.796 0 0 0 1.014 6.139L.825 18.434l4.702-1.228a8.894 8.894 0 0 0 4.238 1.075h.004a8.898 8.898 0 0 0 4.928-1.488 8.84 8.84 0 0 0 3.267-3.962 8.794 8.794 0 0 0-1.92-9.622ZM9.77 16.79h-.002a7.388 7.388 0 0 1-3.752-1.023l-.27-.159-2.79.729.744-2.709-.175-.277a7.313 7.313 0 0 1 .89-8.946 7.401 7.401 0 0 1 8.923-1.378 7.352 7.352 0 0 1 3.203 3.52 7.309 7.309 0 0 1 .367 4.737 7.337 7.337 0 0 1-2.623 3.968 7.394 7.394 0 0 1-4.515 1.537v.001Z" />
    </g>
    <defs>
      <linearGradient id="b" x1={9.732} x2={9.732} y1={18.125} y2={0.927} gradientUnits="userSpaceOnUse">
        <stop stopColor="#20B038" />
        <stop offset={1} stopColor="#60D66A" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M.722.619h18.02v18.02H.723z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
