import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <g fill="current" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M3.258 1.973a.814.814 0 0 0-.815.815v10.424c0 .45.365.815.815.815h9.121c.45 0 .815-.365.815-.815V5.596L9.57 1.973H3.258Zm-1.792.815c0-.99.802-1.792 1.792-1.792h6.38c.216 0 .423.086.576.238l3.719 3.719c.152.153.238.36.238.576v7.683c0 .99-.802 1.792-1.792 1.792H3.258c-.99 0-1.792-.802-1.792-1.792V2.788Z" />
      <path d="M9.285 1.642c0-.494.596-.74.945-.392l3.687 3.687a.554.554 0 0 1-.392.945H9.838a.554.554 0 0 1-.553-.553V1.642Zm.977 1.022v2.241h2.24l-2.24-2.24ZM4.724 8c0-.27.218-.489.488-.489h5.213a.489.489 0 1 1 0 .978H5.212A.489.489 0 0 1 4.724 8ZM4.724 10.606c0-.27.218-.488.488-.488h5.213a.489.489 0 1 1 0 .977H5.212a.489.489 0 0 1-.488-.489Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .181h15.637v15.637H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgComponent;
