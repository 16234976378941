import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={15} viewBox="0 0 14 15" fill="none" {...props}>
    <path stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.3} d="M7 2.728a.706.706 0 0 1 0-1.412M7 2.728a.706.706 0 0 0 0-1.412M7 14.017a.706.706 0 0 1 0-1.411M7 14.017a.706.706 0 0 0 0-1.411M7 8.372a.706.706 0 1 1 0-1.41M7 8.372a.706.706 0 1 0 0-1.41" />
  </svg>
);

export default SvgComponent;
