import React, { useState } from "react";
import { VideoLayout } from "../VideoLayout";
import Image from "rc-image";
import { Link } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import clsx from "clsx";

export const Header = () => {
  const [demo, setDemo] = useState(false);
  const clients = [
    {
      svg: "assets/companies-svg/arya-ag.jpeg",
      name: "Arya Ag",
    },
    // {
    //   svg: "assets/companies-svg/betterplace.jpeg",
    //   name: "Better Place",
    // },
    {
      svg: "assets/companies-svg/finsall.png",
      name: "Finsall",
    },
    {
      svg: "assets/companies-svg/dehaat_sqaure_logo.png",
      name: "Dehaat",
    },

    {
      svg: "assets/companies-svg/JioGenNext_square_no_bg.png",
      name: "JioGenNext",
    },

    {
      svg: "assets/companies-svg/Rivulis_logo_without_bg.png",
      name: "Rivulis",
    },

    {
      svg: "assets/companies-svg/salaam_kisaan_square_logo-removebg-preview.png",
      name: "Salaam Kisaan",
    },
    {
      svg: "assets/companies-svg/rannkly_square.webp",
      name: "Rannkly",
    }
    // {
    //   svg: "assets/companies-svg/oto-capital.jpeg",
    //   name: "Oto Capital",
    // },
    // {
    //   svg: "assets/companies-svg/pw.png",
    //   name: "Physics Wala",
    // },
    // {
    //   svg: "assets/companies-svg/red-cl.png",
    //   name: "Rc Labs",
    // },
    // {
    //   svg: "assets/companies-svg/tring.png",
    //   name: "Tring",
    // },
  ];
  return (
    <React.Fragment>
      {demo && (
        <>
          <div className="fixed z-50 h-full flex items-center">
            <div className="w-full">
              <div className="w-[90%]">
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      setDemo(false);
                    }}
                    className="bg-black text-white px-10 py-2 my-2"
                  >
                    CLOSE
                  </button>
                </div>
              </div>
              <div className="w-[90%] h-full">
                <VideoLayout
                  auto={true}
                  className={"h-[30%] md:h-[50%] w-[90%] md:w-[50%] mx-auto"}
                  source={
                    "https://website-videos-recount-ai.s3.ap-south-1.amazonaws.com/1_min_video_RecountAI_new_models.mp4"
                  }
                />
              </div>
            </div>
          </div>
          <div className="fixed backdrop-blur-md w-[100%] h-full z-20"></div>
        </>
      )}
      <div className="bg-white mt-[30px] h-full">
        <div className="flex flex-col justify-around w-[80%] h-full gap-20 mx-auto py-5 md:py-16">
          <div className="flex justify-between flex-col-reverse gap-10 md:flex-row">
            <div className="flex flex-col gap-5 md:gap-10">
              <div className="flex flex-col text-3xl gap-4 mt-0 md:mt-10">
                <div className="md:text-5xl text-[#01203AE5] tracking-wider font-magnetik-bold">
                  Personalised Video<br /> Communication
                </div>
                <div className="font-semibold tracking-widest text-[#01203AE5] capitalize">
                  for{" "}
                  <TypeAnimation
                    className="text-[#4BD1A0]"
                    sequence={[
                      "Lead Generation",
                      1000,
                      "Upsell/Cross-sell",
                      1000,
                      "Customer Engagement",
                      1000,
                    ]}
                    repeat={Infinity}
                  />
                </div>
                <div className="flex flex-col gap-4 text-[20px] h-full mt-8">
                  We generate videos instantly from script, <br />made uniquely for each user.
                </div>
              </div>
              <div className="text-white flex gap-2 text-lg md:text-xl md:gap-14 flex-1 items-center -ml-2">
                <div>
                  <Link
                    to={"/trial"}
                    className="bg-[#01203AE5] whitespace-nowrap text-white border  flex rounded-full shadow-md shadow-[#4BD1A0]  items-center gap-4 py-2 px-4 md:py-4 md:px-6 hover:bg-[#4BD1A0] hover:border-[#01203AE5] hover:text-[#01203AE5] hover:text-white transition-all duration-200"
                  >
                    Try for free
                  </Link>
                </div>
                <div>
                  <button
                    onClick={() => {
                      setDemo(true);
                    }}
                    className="bg-white whitespace-nowrap text-[#01203AE5] border  border-[#4BD1A0] flex rounded-full shadow-md shadow-[#4BD1A0] items-center gap-4 py-2 px-4 md:py-4 md:px-6 hover:bg-[#01203AE5] hover:border-[#4BD1A0] hover:text-white transition-all duration-200"
                  >
                    ▶&nbsp;&nbsp;Watch Demo
                  </button>
                </div>
              </div>
            </div>
            <img
              className="w-full md:w-[40%]"
              src={process.env.PUBLIC_URL + "assets/home.gif"}
              alt="#"
            />
          </div>
          <div className="flex justify-evenly items-center flex-wrap ">
            {clients.map((item, index) => {
              return (
                <div
                  key={index}
                  className="w-[30%] md:w-[14%] py-4 flex flex-col justify-between items-center"
                >
                  <Image
                    alt="#"
                    className={clsx(
                      item.name === "Finsall"
                        ? "h-[40px] md:h-[60px]"
                        : "h-[70px] md:h-[140px]",
                      item.name === "Rc Labs" && "h-[40px] md:h-[70px]"
                    )}
                    src={process.env.PUBLIC_URL + item.svg}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
