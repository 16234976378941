import React from "react";
import { SearchInput, VideoHistoryCard } from "../components/ui";
import AppWrapper from "../components/ui/AppWrapper";
import UserProfileMenu from "../components/UserProfile/menu"
interface VideoHistoryPageProps { }
const videoData = [
  {
    id: "photos",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/video1.jpeg",
    prompt: "Give me an advisory video on the topic leaf folder in under 30 seconds in english with no presenter.. 1-5 acre farmer is my target audience."
  },
  {
    id: "videos",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/video2.jpeg",
    prompt: "Give me an advisory video on the topic leaf folder in under 30 seconds in hindi with female presenter. 1-5 acre farmer is my target audience."
  },
  {
    id: "videos",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/video3.jpeg",
    prompt: "Give me an advisory video on the topic leaf folder  in hindi with male presenter in under 30 seconds. 1-5 acre farmer is my target audience."
  },
  {
    id: "videos",
    src: "https://d3o847c916r6no.cloudfront.net/ws_assets/video4.jpeg",
    prompt: "Give me a product video on oozier in under 30 seconds, include price and dosage"
  },
];
const VideoHistoryPage: React.FC<VideoHistoryPageProps> = () => {
  return (
    <AppWrapper>
      <section className="flex-1 flex flex-col overflow-hidden">
        <header className="w-full h-20 border-b border-black/10 flex-shrink-0 flex items-center justify-between px-6">
          <h1 className="text-base font-medium text-[#19213D]">Video Library</h1>
          <UserProfileMenu />
        </header>
        <article className="flex-1 flex flex-col w-full relative overflow-y-auto main-bg">
          <div className="flex flex-col flex-1 items-center mt-16">
            <div className="flex flex-col items-center gap-6">
              <SearchInput placeholder="Search video..." />
              <div className="bg-[#F0F2F5] h-[1px] w-full" />
              <div className="grid grid-cols-3 gap-x-4 gap-y-6 w-[880px] mb-8">
                {videoData.map(item => (
                  < VideoHistoryCard
                    key={item.id}
                    id={item.id}
                    src={item.src}
                    prompt={item.prompt}
                  />
                ))}
              </div>
            </div>
          </div>
        </article>
      </section>
    </AppWrapper>
  );
};

export default VideoHistoryPage;
