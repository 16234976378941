import React from "react";

interface SavedTemplateCardProps {
  src: string;
  header: string;
  date: string;
  variable: string;
  prompt: string;
}

const SavedTemplateCard: React.FC<SavedTemplateCardProps> = (SavedTemplateCardProps) => {
  return (
    <div className="flex flex-col gap-y-6 w-[880px]">
      <div className="flex items-center bg-white rounded-2xl border border-[#F0F2F5] hover:border-[#2388FF] shadow-sm">
        <img src={SavedTemplateCardProps.src} className="w-72 h-full object-cover rounded-l-2xl flex-shrink-0" alt="" />
        <div className="flex flex-col justify-center flex-1 py-6 px-8 gap-4">
          <h3 className="text-[#19213D] text-base font-semibold">{SavedTemplateCardProps.header}</h3>
          <p className="text-sm">
            Prompt: <span className="text-[#666F8D]">{SavedTemplateCardProps.prompt}</span>
          </p>
          <div className="flex items-center gap-2">
            <button className="text-xs py-1 px-2 rounded-full text-[#4C5EFF] bg-[#E3EBFF] font-medium">{SavedTemplateCardProps.date}</button>
            <button className="text-xs py-1 px-2 rounded-full text-[#007AFF] bg-[#E9F3FF] font-medium">{SavedTemplateCardProps.variable}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedTemplateCard;
