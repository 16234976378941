import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path fill="current" d="M11.529 10.471a.667.667 0 1 0 .942-.942l-4-4a.667.667 0 0 0-.942 0l-4 4a.667.667 0 1 0 .942.942L8 6.943l3.529 3.528Z" />
  </svg>
);

export default SvgComponent;
