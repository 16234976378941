import React, { useEffect } from 'react';

export const RegisterTypeForm = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;

    // Append script to the body
    document.body.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="items-end" style={{ marginTop: '80px' }}  data-tf-live="01HWAE94T3DJEW1EN8MDAJTPMK"></div>
  );
}

export default RegisterTypeForm;