// External imports
import { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

// Internal imports
import './App.css';
import { CustomerCards, CustomerStories, Footer, Header, Navbar, ProductFeature, ValueProps, RegisterTypeForm } from './components';
import { VideoPersonalisePage, KnowledgeBasePage, MediaLibraryPage, NewCampaignPage, NewTemplatePage, NewVideoPage, SavedTemplatesPage, VideoHistoryPage, VideoPreviewPage, EditVideoPage } from "./pages";

import SignIn from './pages/SignIn';
import { useAuth } from './context/AuthContext';
const router = [
  {
    path: "/video/new/preview",
    element: <VideoPreviewPage />,
  },
  {
    path: "/video/new/personalise",
    element: <VideoPersonalisePage />,
  },
  {
    path: "/video/new/edit",
    element: <EditVideoPage />,
  },
  {
    path: "/video/new",
    element: <NewVideoPage />,
  },
  {
    path: "/video/history",
    element: <VideoHistoryPage />,
  },
  {
    path: "/template/new",
    element: <NewTemplatePage />,
  },
  {
    path: "/template/saved/run",
    element: <NewCampaignPage />,
  },
  {
    path: "/template/saved",
    element: <SavedTemplatesPage />,
  },
  {
    path: "/media-library",
    element: <MediaLibraryPage />,
  },
  {
    path: "/knowledge-base",
    element: <KnowledgeBasePage />,
  },
];
function App(): JSX.Element {
  const [isVisible, setIsVisible] = useState(false);
  const { user } = useAuth()
  const visibleFixed = false;
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 600; // Adjust this value as needed

      if (scrollPosition > threshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Routes>
      {/* Landing Page */}
      <Route
        path="/"
        element={
          <div className="bg-[#01203A] h-full w-full y-hidden">
            <div className='w-full mx-auto flex flex-col gap-8 '>
              <Navbar visible={isVisible} showHeaderLinks={true} />
              <Header />
            </div>
            <div className='w-full'>
              <ValueProps />
              <ProductFeature />
              <CustomerStories />
              <CustomerCards />
              <Footer />
            </div>
          </div>
        }
      />
      <Route path="/trial" element={
        <div className='w-full mx-auto flex flex-col gap-8 '>
          <Navbar visible={visibleFixed} showHeaderLinks={true} />
          <RegisterTypeForm />
          <Footer />
        </div>
      } />
      <Route path="/login" element={
        user ? (
          <Navigate to="/video/new" replace />
        ) : (
            <div className='w-full mx-auto flex flex-col gap-8 '>
              <Navbar visible={isVisible} showHeaderLinks={true} />
              <SignIn />
              <Footer />
            </div>
          )
      } />
      {router.map((route) => (
        <Route key={route.path} path={route.path} element={user ? route.element : <Navigate to="/login" replace />} />
      ))}
    </Routes>
  );
}

export default App;
