import clsx from "clsx";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { BsFillPlayFill, BsFillPauseFill } from "react-icons/bs";

interface VideoLayoutProps {
  source: string;
  className: string;
  auto?: boolean; // Optional prop with default value
}


export const VideoLayout = ({ source, className, auto=false }: VideoLayoutProps) => {
  const [playVideo, setPlayVideo] = useState(false);
  function togglePlayVideo() {
    setPlayVideo(!playVideo);
  }
  return (
    <div
      onClick={togglePlayVideo}
      style={{ objectFit: "fill" }}
      className={clsx(
        "relative mx-auto border-[6px] shadow shadow-[#01203AE5] rounded-xl overflow-hidden z-[10]",
        className
      )}
    >
      <ReactPlayer
        url={source}
        muted={false}
        height={"100%"}
        width="100%"
        playing={
          auto
            ? auto && !playVideo
              ? true
              : playVideo
              ? false
              : true
            : playVideo
        }
      />

      <div className="absolute w-full flex items-center justify-center top-1/3">
        <div
          className={clsx(
            "h-12 w-12 bg-white rounded-full items-center justify-center ring-4 ring-white/50 cursor-pointer ",
            !auto
              ? playVideo
                ? "hidden"
                : "flex"
              : !playVideo
              ? "hidden"
              : "flex"
          )}
        >
          {!playVideo || auto ? (
            <BsFillPlayFill className="h-8 w-8 text-green-800 ml-1" />
          ) : (
            <BsFillPauseFill className="h-8 w-8 text-green-800" />
          )}
        </div>
      </div>
    </div>
  );
};
